.login-page {
    height: 100vh;
    width: 100%;
    background: url("/src/accets/images/bg-login.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-page__container {
    width: 440px;
}
.login-page .login-page__logo {
    width: 300px;
    display: block;
    margin: 0 auto;
}
.login-page .inputParrent {
    margin-top: 20px;
}
.login-page input {
    background-color: #F3F6F9;
    background-clip: padding-box;
    border: 1px solid #F3F6F9;
    border-radius: 0.42rem;
    width: 100%;
    height: 55px;
    outline: none;
    padding: 5px 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}
.login-page input::placeholder {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px !important;
    font-weight: 400;
    color: red;
}
.login-page button {
    border-radius: 0.42rem;
    border: none;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 17px 30px;
    color: #fff;
    background: linear-gradient(45deg,#80aeba 2%,#699499 46%,#669295);
    display: block;
    width: 100%;
    margin-top: 20px;
}