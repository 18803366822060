.multiple-field {

}
.multiple-field__container {
    border: 1px solid #dedede;
    font-size: 13px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    text-align: left;
    width: 100%;
    padding: 8px 10px;
    background: #f9f9f9;
    font-family: 'Montserrat', sans-serif;
    color: #2b2b2b;
    outline: none !important;
    height: 35px;
    border-radius: 4px;
    font-weight: 400;
}
.multiple-field__option {
    border-radius: 2px;
    color: #424242;
    font-size: 11px;
    background: #e3e3e3;
    margin-right: 3px;
    padding: 3px 6px;
    margin-bottom: 3px;
    cursor: pointer;
}
.multiple-field__option:hover {
    background: linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%);
    color: #FFFFFF;
}