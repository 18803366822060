.flex {
  display: flex !important;
}
.z2 {
  z-index: 2 !important;
}
.ml-a {
  margin-left: auto !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mr-r3 {
  margin-right: 3rem !important;
}
.mr-r1 {
  margin-right: 1rem !important;
}
.mr-r05 {
  margin-right: 0.5rem !important;
}
.ml-r05 {
  margin-left: 0.5rem !important;
}
.ml-r1 {
  margin-left: 1rem !important;
}
.mr-2 {
  margin-right: 2px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.hw-25 {
  width: 25px !important;
  height: 25px !important;
}
.hw-24 {
  width: 24px !important;
  height: 24px !important;
}
.hw-23 {
  width: 23px !important;
  height: 23px !important;
}
.hw-22 {
  width: 22px !important;
  height: 22px !important;
}
.hw-21 {
  width: 21px !important;
  height: 21px !important;
}
.hw-17 {
  width: 17px !important;
  height: 17px !important;
}
.hw-18 {
  width: 18px !important;
  height: 18px !important;
}
.hw-16 {
  width: 16px !important;
  height: 16px !important;
}
.ft {
  fill: transparent !important;
}
.star {
  stroke: #ff9f43 !important;
}

.email-bttn {
  padding: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
  border: 0;
}

.email-icon-gray-1 {
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: #6f6b7d;
}
.email-icon-gray-2 {
  width: 20px;
  height: 20px;
  fill: #6f6b7d;
  stroke: none;
}
.email-icon-white-1 {
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: #fff;
}
.email-icon-white-2 {
  width: 20px;
  height: 20px;
  fill: #ffffff;
  stroke: transparent;
}

.folder-active .email-icon-gray-1 {
  stroke: #7367f0;
}
.folder-active .email-icon-gray-2 {
  fill: #7367f0;
}

.email-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 0.375rem;
}

.email-sidebar {
  /* border: 1px solid tomato; */
  position: static;
  height: auto;
  /* width: calc(20% - 3px); */
  width: calc(20% - 1px);
  z-index: 4;
  /* flex-basis: calc(20% - 3px); */
  flex-basis: calc(20% - 1px);
  transition: all 0.2s;
  border-right: 1px solid #dbdade;
}

.email-main {
  /* width: calc(80% - 2px);
  border: solid 1px tomato; */
  width: 80%;
  position: relative;
  overflow: hidden;
}

.sidebar-bttn-compose {
  padding: 1.1rem 1.5rem;
  display: grid;
}

.sidebar-bttn {
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  cursor: pointer;
  color: #fff;
  background-color: #7367f0;
  border-color: #7367f0;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: all 0.135s ease-in-out;
  transform: scale(1.001);
  letter-spacing: 0.43px;
  border-radius: 0.375rem;
  border-width: 1px;
  disabled-opacity: 0.65;
  height: 40px;
}
.sidebar-bttn:hover {
  color: #fff;
  background-color: #685dd8;
  border-color: #685dd8;
}

.sidebar-bttn:active {
  background-color: #5a4fcb;
}

.sidebar-filter {
  height: calc(100vh - 16.6rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
.filter-folders {
  margin-bottom: 1.5rem;
}
.filter-folder:first-child {
  margin-top: 0;
}

.filter-folder {
  padding: 0.4375rem 1.5rem;
  border-left: 2px solid rgba(0, 0, 0, 0);
  display: flex;
  height: 30px;
}
.folder-active {
  border-color: #7367f0;
  color: #7367f0;
}

.list-letter {
  border-bottom: 1px solid #dbdade;
  padding: 0.875rem 1rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.letter {
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
}

.letter-check {
  padding-left: 1.7rem;
}

.letter-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 1rem;
}

.letter-content {
  margin-right: 0.5rem;
}

.letter-info {
  margin-left: auto;
  margin-right: 0.45rem;
}

.email-main-header {
  padding: 1rem;
  border-bottom: 1px solid #dbdade;
}

.header-searchbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchbar {
  display: flex;
  width: 100%;
  align-items: center;
  /* border-radius: 0.375rem; */
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.search-icon {
  background-image: url("../images/icons/search.svg");
  margin-top: 6.5px;
  padding-right: 0.75rem;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 24px;
}
.searchbar-label {
  width: 90%;
  padding-bottom: 0.5rem;
}

.searchbar-input {
  display: block;
  padding: 0.422rem 0;
  font-size: 0.9375rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6b7d;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border: none;
  outline: none;
}

.header-filter {
  height: 22.5px;
  display: flex;
}

.emails-list-header-hr {
  margin-top: 0.3rem;
  margin-right: -1rem;
  margin-left: -1rem;
  color: #dbdade;
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

.email-checkbox-label {
  width: 0;
  height: 0;
  display: inline-block;
}

.email-checkbox {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 18px;
  height: 18px;
  border-radius: 0.25em;
  border: 0.15em solid #dbdade;
  background-color: white;

  outline: none;
  cursor: pointer;

  margin-bottom: 0;
  margin-right: 0.5em;
  margin-left: -1.7em;
}

.email-checkbox:checked {
  background-position-x: -3px;
  background-position-y: -3px;
  border-color: #7367f0;
  background-image: url("../images/icons/checkbox.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  position: relative;
}
.email-checkbox:focus {
  border-color: #7367f0;
}

.letter-username {
  font-weight: 500;
  color: #5d596c;
  line-height: 1.37;
  font-size: 15px;
  padding-right: 1rem;
}

.letter-subject {
  font-weight: 400;
  color: #6f6b7d;
  line-height: 1.37;
  font-size: 15px;
  padding-right: 1rem;
}

.letter-time {
  font-size: 13px;
  font-weight: 400;
  color: #a5a3ae;
  display: inline-block;
  width: 60px;
}
.marked-read {
  background-color: rgba(75, 70, 92, 0.04);
}

.searchbar-input::placeholder {
  font-size: 15px !important;
  transition: 0.15s;
}

.searchbar-input:focus::placeholder {
  padding-left: 5px !important;
}

.filter-folder-label {
  color: #6f6b7d;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.badge-1 {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  color: #7367f0 !important;
  background-color: #eae8fd !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge-2 {
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: #fff1e3 !important;
  color: #ff9f43 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.folder-label {
  font-weight: 500;
  font-size: 15px;
  margin-left: 8px;
}

.folder-active .folder-label {
  color: #7367f0;
}

.header-filter-actions {
  display: flex;
  align-items: center;
  position: relative;
}

.header-filter-checkbox {
  margin-right: 0.5em;
}

.email-checkbox-all {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 18px;
  height: 18px;
  border-radius: 0.25em;
  border: 0.15em solid #dbdade;
  background-color: white;

  outline: none;
  cursor: pointer;

  margin-bottom: 0;
  margin-right: 0.5em;
  margin-left: 4.5px;
}

.email-checkbox-all:checked {
  background-position-x: -3px;
  background-position-y: -3px;
  border-color: #7367f0;
  background-image: url("../images/icons/checkbox.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  position: relative;
}

.email-checkbox-all:focus {
  border-color: #7367f0;
}

.header-filter-pagination {
  display: flex;
  margin-left: auto;
}

.pagination-text {
  font-size: 13px;
  font-weight: 400;
  color: #a5a3ae;
  margin-top: 4px;
  margin-right: 0.5rem;
}

.move-to-folder-select {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 140px;
  left: 95px;
  top: 25px;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}

.email-tab-label {
  display: flex;
  align-items: center;
  line-height: 1.375;
  margin: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  padding: 0.42rem 1rem;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 400;
  color: #5d596c;
}

.email-tab-label:hover {
  color: #8176f2;
  background-color: rgba(115, 103, 240, 0.08);
}
.email-tab-label:active {
  color: #fff;
  background-color: #7367f0;
}

.email-tab-label:hover .email-icon-gray-1 {
  stroke: #8176f2;
}
.email-tab-label:hover .email-icon-gray-2 {
  fill: #8176f2;
}
.email-tab-label:active .email-icon-gray-1 {
  stroke: #ffffff;
}
.email-tab-label:active .email-icon-gray-2 {
  fill: #ffffff;
}

.mark-read {
  background-color: rgba(75, 70, 92, 0.04);
}

.email-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1200;
}

.email-modal {
  position: fixed;
  bottom: 0;
  right: 0;

  margin-right: 24px;
  margin-bottom: 24px;

  width: 50rem;
  /* height: 50rem; */

  background-color: #fff;
  border-radius: 6px;
}

.compose-header {
  position: relative;
  display: flex;
  background: #f8f7fa;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-top-left-radius: 6px;
}

.modal-title {
  font-size: 18px;
  font-weight: 500;
}

.modal-bttn {
  background: #f8f7fa;
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border: 0;
  position: absolute;
  top: -10px;
  right: -10px;
  fill: #5a4fcb;
  transition: 0.3s;
  padding: 0;
  width: 30px;
  height: 30px;
}
.modal-svg {
  margin: 5px 0;
  width: 30px;
  height: 20px;
  fill: #5d596c;
}
.modal-bttn:hover {
  top: -7px;
  right: -7px;
}
.modal-bttn:active {
  background-color: #7367f0;
}
.modal-bttn:active .modal-svg:active {
  fill: #ffffff;
}

.modal-email-label-wrapper {
  display: flex;
  align-items: center;
  width: calc(100% - 24px - 16px);
  padding: 12px;
}

.modal-email-preinput {
  font-size: 13px;
  font-weight: 400;
  color: #5d596c;
  margin-top: 11px;
  margin-right: 8px;
}
.modal-email-input {
  margin-left: 8px;
  /* padding: 0.422rem 0; */
  font-size: 0.9375rem;
  width: 40rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6f6b7d;
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  border: none;
  outline: none;
}

.modal-email-input::placeholder {
  font-size: 15px !important;
  color: #6f6b7d !important;
  transition: 0.15s;
}

.modal-email-input:focus::placeholder {
  padding-left: 5px !important;
}

.modal-email-toggle-wrapper {
  margin-left: auto;
}

.email-toggle:first-of-type {
  margin-right: 5px;
  border-right: 1px solid;
  padding-right: 5px;
}

.modal-emails-hr {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  color: #dbdade;
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

.modal-email-content {
  width: 47rem;
  height: 20rem;
  margin-left: 6px;
  padding: 1rem;
}
.modal-email-content-text {
  width: 99%;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  color: rgb(111, 107, 125);
}
.bold-hr {
  border-width: 2px;
}

.modal-email-send-form {
  position: relative;
  display: flex;
  padding: 1rem;
  width: 100%;
  height: 3rem;
  align-items: center;
}

.email-send-send-bttn {
  width: 6rem;

  padding: 1rem 0.5rem;
  border: 1px solid #685dd8;
  background-color: #7367f0;

  height: 2.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.135s ease-in-out;
  transform: scale(1.001);
  letter-spacing: 0.43px;
  color: #ffffff;

  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0;
}

.email-send-send-bttn:hover {
  background-color: #685dd8;
}

.email-send-select-bttn {
  padding: 1rem 0.5rem;
  border: 1px solid #685dd8;
  background-color: #7367f0;

  height: 2.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.135s ease-in-out;
  transform: scale(1.001);
  letter-spacing: 0.43px;
  color: #ffffff;

  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
}

.email-send-select-bttn:hover {
  background-color: #685dd8;
}

.send-select {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 140px;
  left: 113px;
  top: -72px;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}

.email-page {
  width: 100%;
  height: 100%;
  background-color: #f8f7fa;
  position: absolute;
  transition: 300ms;
  left: 0;
  z-index: 3;
  overflow: scroll;
}

.email-closed {
  left: 100%;
}

.email-page-header {
  padding: 16px;
  border-bottom: 1px solid #dbdade;
  position: sticky;
  background-color: #fff;
  top: 0;
  z-index: 3;
}
.header-title {
  height: 40px;
  display: flex;
  align-items: center;
}

.tittle-text {
  font-size: 15px;
  font-weight: 500;
  color: #5d596c;
}

.three-dot-select {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 140px;
  right: 20px;
  top: 56px;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}

.email-move-to-select {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 140px;
  right: 190px;
  top: 190px;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}

.mb-0 {
  margin-bottom: 0;
}

.email-page-content {
  margin: 0;
  /* height: 100rem; */
  background-color: #f8f7fa;
}

.email-page-content-letter {
  height: max-content;
  background-color: #ffffff;
  /* border: 1px solid tomato; */
  border-bottom: 1px solid #dbdade;
  margin-bottom: 50px;
}

.content-letter-info {
  padding: 8px;
  position: relative;
  /* border: 1px solid seagreen; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdade;
}

.letter-info-person-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 1rem;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
}

.letter-info-person-name {
  font-size: 15px;
  font-weight: 500;
  color: #5d596c;
}
.letter-info-person-email {
  color: #a5a3ae;
  font-size: 13px;
  font-weight: 400;
}

.letter-info-full-time {
  font-size: 15px;
  font-weight: 400;
  color: #a5a3ae;
  margin-left: auto;
  margin-right: 1rem;
}

.letter-info-select {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 140px;
  right: 20px;
  top: 50px;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}

.email-page-content-reply {
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 1rem;
  background-color: #ffffff;
  border-bottom: 1px solid #dbdade;
}

.content-reply-bttn {
  width: 6rem;

  padding: 1rem 0.5rem;
  border: 1px solid #685dd8;
  background-color: #7367f0;

  height: 2.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.135s ease-in-out;
  transform: scale(1.001);
  letter-spacing: 0.43px;
  color: #ffffff;

  border-radius: 6px;
  margin-top: 1rem;
  margin-left: auto;
}

.content-reply-bttn:hover {
  background-color: #685dd8;
}
