.new-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
.new-layout header {
    flex-shrink: 0;
}
.new-layout__bottom {
    display: flex;
    padding-top: 60px;
    flex-grow: 1;
}
.new-layout__profile {
    background: url("/src/accets/images/user-info.jpg") center bottom;
    height: 130px;
    position: relative;
}
.new-layout__user-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background: rgba(000,000,000,0.7);
    height: 35px;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 35px;
    padding-left: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}
.new-layout aside {
    flex-shrink: 0;
    background: #FFFFFF;
}
.new-layout main {
    flex-grow: 1;
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    overflow: auto;
}
.new-layout__nav {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.new-layout__nav-item {
    margin: 0 5px;
    padding: 7px 5px;
    display: flex;
    align-items: center;
}
.new-layout__nav-item i {
    color: #99abb4;
    font-size: 22px;
}
.new-layout__nav-item span {
    color: #8d8d8d;
    font-size: 15px;
    font-weight: 400;
    margin-left: 6px;
}
.new-layout__nav-item.active, .new-layout__nav-item:hover {
    background: linear-gradient(45deg, #80aeba 2%,#699499 46%,#669295 100%);
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.new-layout__nav-item.active i, .new-layout__nav-item.active span,
.new-layout__nav-item:hover i, .new-layout__nav-item:hover span{
    color: #FFFFFF;
}
.new-layout__nav-item:hover