.history-table {

}
.history-table table {
    width: 100%;
}
.history-table h2 {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
    color: #565656;
}
.history-table th {
    font-weight: 400;
    font-size: 11px;
    color: #636f7f;
    background: #f9fafb;
    text-align: left;
    padding: 14px 0 14px 10px;
}

.history-table td {
    text-align: left;
    font-size: 13px;
    color: #1e2248;
    padding: 6px 10px 14px 10px;
    border-bottom: 1px solid #dee2e6;
    line-height: inherit !important;
}
.history-table tr:last-child td {
    border-bottom: none !important;
}
.history-table-item__container {
    background: #FFFFFF;
}
.history-table-item {
    display: flex;
    width: 100%;
    margin-top: 10px;
}
.history-table-item i {
    font-size: 35px;
    flex-shrink: 0;
    margin-right: 10px;
    margin-left: 5px;
    margin-top: 10px;
    color: #6c6c6c;
}
.history-table-item__container {
    flex-grow: 1;
    border: 1px solid #e0e0e0;
    box-shadow: 1px 0px 20px rgb(0 0 0 / 5%);
    padding: 15px 15px;
}
.history-table-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding: 0px 0 13px 0;
}
.history-table-item__header b {
    font-size: 15px;
    font-weight: 500;
    color: #2b2b2b;
}
.history-table-item__header a {
    font-size: 14px;
    font-weight: 400;
    margin-right: 15px;
}
.history-table-item__header em {
    font-size: 12px;
}
.history-table-item__body {
    font-size: 14px;
    padding-top: 10px;
}