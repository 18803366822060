.drag-list {
    /*max-width: 500px;*/
    padding: 10px;
}
.connection {
    border-bottom: 2px solid #8f8686;
}
.connection_new {
    border-bottom: 2px solid #8f8686;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.connection_new > div {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.connection_new > div > b {
    font-size: 10px;
    text-transform: uppercase;
    margin-right: 4px;
    margin-top: -2px;
}
.connection span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    display: inline-block;
    font-size: 11px;
    color: #8e8585;
    text-shadow: none;
    text-transform: uppercase;
}
.connection_new button {
    box-shadow: none;
    border-radius: 0;
}
.drag-icon {
    font-size: 22px;
    margin-right: 10px;
    cursor: all-scroll;
}
.drag-list__parent {
    display: flex;
    align-items: center;
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
}
.drag-list__item {
    background: #FFFFFF;
    margin-bottom: 5px;
    height: 40px;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    border: 1px solid #e8e8e8;
    padding: 2px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: all-scroll;
    flex-grow: 1;
}
.drag-list__item > div {
    padding: 5px;
}
.drag-list__item input[type='color'] {
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.drag-list__item input[type='text'], .drag-list__item input[type='number'] {
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    padding: 5px 10px;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
}
.drag-list__item-delete {
    color: #d37873;
    cursor: pointer;
    font-size: 19px;
}
.drag-list__buttons {
    display: flex;
    justify-content: space-between;
}
.drag-list__buttons button {
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 16%);
    padding: 10px 15px;
}
.drag-list__buttons button:hover {
    opacity: 0.8;
}
.drag-list__buttons-create {
    background: linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%);
    /*margin-left: 33px;*/
}

.list-settings-new {

}
.list-settings-new ol {
    margin-left: 25px;
    margin-top: 5px;
}
.list-settings-new li {
    color: #8e8585;
    font-size: 14px;
    line-height: 22px;
}
.settings-new-button {
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    margin: 0;
}
.static-color-item {
    width: 30px;
    height: 30px;
    border-radius: 4px;
}
.list-settings-new th {
    padding: 11px;
    font-weight: 600;
    font-size: 13px;
}
.list-settings-new td {
    padding: 10px;
}