.base-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #f6f6f6;
  border-radius: 0.375rem;
  margin-top: 30px;
  margin-bottom: 30px;
  /* width: 80rem; */
  /* height: fit-content; */
}
.article-list-container {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  width: 60rem;
  /* height: fit-content; */
  margin-right: 30px;
  border-radius: 0.375rem;
}
.category-list-container {
  background-color: #fff;
  width: 15rem;
  height: fit-content;

  border-radius: 0.375rem;
}

.article-searchbar-label {
  position: relative;
  width: 100%;
}
.article-searchbar-input {
  display: block;
  width: 80%;

  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;

  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #4b5675;

  background-clip: padding-box;
  border: 1px solid;

  appearance: none;
  outline: none;

  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 0.325rem 1.5rem;
  padding-left: 3.5rem !important;

  border-radius: 0.325rem;
}

.article-list-header {
  width: inherit;
  margin-bottom: 50px;
}
.article-list-searchbar {
  width: inherit;
}

.bgr-search {
  position: absolute;
  top: 6px;
  left: 75px;
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: #6f6b7d;
}

.list-title {
  margin-left: 50px;
  margin-bottom: 30px;

  color: #071437;
  font-size: 1.75rem;
  font-weight: 500;
}

.article-item {
  margin-bottom: 20px;
}
.article-item:last-of-type {
  margin-bottom: 0;
}

.article-item-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 0.375rem;
}

.article-item-img {
  width: 35px;
  height: 35px;
  fill: transparent;
  stroke: #000;
  margin-right: 10px;
  margin-left: 50px;
}

.article-title {
  color: #071437;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.article-title:hover {
  color: #3e97ff;
}
.article-category {
  color: #78829d;
  background-color: #f9f9f9;
  display: inline-flex;
  align-items: center;

  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.225rem;
  padding-bottom: 0.225rem;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 0.425rem;
}

.article-add-info {
  color: #99a1b7;
  font-weight: 500;
  font-size: 0.8rem;
}

.category-item-label {
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 0.375rem;
  padding: 0.35rem 2rem;
  margin: 0 0.875rem;
  color: #6f6b7d;
  font-size: 0.9375rem;
  transition-duration: 0.3s;
  transition-property: color, background-color;
}

.category-item {
  margin: 0.125rem 0;
  flex: 0 0 auto;

  padding: 0;
  list-style: none;
}
.category-item-active-label {
  border-color: #7367f0;
  background-color: #7367f0;
  color: #fff;
}

.abs-bttn {
  position: absolute;
  top: -20px;
  right: 30px;
}
.abs-bttn-second-type {
  position: absolute;
  top: -20px;
  left: 30px;
}

.unique-article-container {
  background-color: #fff;
  border-radius: 0.375rem;
  margin-top: 30px;
  position: relative;
  display: flex;
  width: 78rem;
}

.unique-article-main {
  margin-top: 60px;
  width: inherit;

  border-radius: 0.375rem;
}

.title-container {
  display: flex;
  justify-content: center;
}

.unique-article-title {
  font-weight: 500;
  color: #252f4a;
  font-size: 1.75rem;
}

.edit-article-container {
  background-color: #fff;
  margin-top: 30px;
}
.edit-article-main-container {
  /* background-color: #fff;*/
  padding-top: 30px;
}

.edit-article-bttn-group {
  /* display: flex; */
  position: absolute;
  top: -20px;
  right: 35px;
}

.mla {
  margin-left: auto;
}

.edit-page-close {
  position: absolute;
  top: -15px;
  left: 30px;
  border-radius: 4px;
  padding: 0;
  height: 30px;
  width: 70px;
  border: 0;
}
.edit-page-icon {
  width: 30px;
  height: 30px;
  fill: #fff;
  stroke: none;
}
.edit-article-editor {
  margin-top: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.bottom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
