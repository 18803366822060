@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&family=Inter:wght@100;300;500;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=PT+Sans:wght@400;700&display=swap");

@charset "utf-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
* {
  outline-color: #669295 !important;
}
::selection {
  background: #669295 !important; /* Safari */
  color: #fff !important;
}
::-moz-selection {
  background: #669295 !important; /* Firefox */
  color: #fff !important;
}
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
::selection {
  background: none;
}
a {
  text-decoration: none;
  color: #669295;
  font-size: 14px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.clear {
  clear: both;
}
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  background: #f6f6f6;
  /*min-width: 1200px;*/
}
.clearLayout header {
  top: -60px !important;
}
.clearLayout .sidebar {
  left: -240px;
}
.clearLayout .page {
  margin: 0 !important;
  width: 100% !important;
}
header {
  position: fixed;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  height: 60px;
  width: 100%;
  z-index: 6;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
header .light-logo {
  max-width: 150px;
  margin-top: 16px;
}
.btn-m {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 0px;
  display: inline-block;
  margin: 3px 0;
  color: #fff;
  border: none;
}
.btn-m:hover {
  box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  background: linear-gradient(45deg, #669295 2%, #699499 46%, #80aeba 100%);
}
.bn {
  height: inherit !important;
  background: none !important;
  border: none !important;
  padding: 0px !important;
  width: inherit !important;
  color: #669295 !important;
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px !important;
}
.chooseItems {
  display: flex;
}
.chooseItems button {
  padding: 10px 20px;
  background: #fff;
  border: 1px solid #bbbbbb;
  border-left: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  outline: none !important;
}
.chooseItems button:first-child {
  border-left: 1px solid #bbb;
  border-radius: 6px 0 0 6px;
}
.chooseItems button:last-child {
  border-radius: 0 6px 6px 0;
}
.chooseItems button.active {
  color: #ffffff;
  background: #669295;
  border-color: #669295;
  font-weight: bold;
}
.task-entity-comments h2 {
  display: none;
}
.novaposhta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b5b5b5;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}
.novaposhta a:first-child {
  color: #5f5f5f;
  font-weight: bold;
  font-size: 15px;
  padding-left: 5px;
}
.button-group-select select {
  border: 1px solid #dedede;
  font-size: 13px;
  text-align: left;
  padding: 8px 10px;
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  height: 40px;
  font-weight: bold;
  width: 200px;
  float: right;
}
@keyframes animations {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes movev {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.success {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  color: #f6fcfb !important;
}
.danger {
  background: #c2263f !important;
  color: #f6fcfb !important;
}
.warning {
  background: #f6d297 !important;
  color: #2b2b2b !important;
}
.info {
  background: #224169 !important;
  color: #fff !important;
}
.grey {
  background: #a7a7a7 !important;
  color: #fff !important;
}

.notif {
  display: inline-block;
  margin: 0px auto;
  position: fixed;
  transition: all 0.5s ease-in-out;
  z-index: 10000;
  width: 325px;
  bottom: 10px;
  left: 15px;
  animation-iteration-count: 1;
}
.notif i {
  font-size: 35px;
  float: left;
  margin-right: 10px;
}
.notif-item {
  border-color: #669295;
  padding: 0.85rem 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  animation: movev 0.2s linear 0s, animations 0.5s linear 4s;
  opacity: 0.8;
  padding: 11px;
}
.notif-item:hover {
  opacity: 1;
}
.notif-item h3 {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 2px;
}
.notif-item p {
  font-size: 13px;
  display: flex;
}
.navbar-header {
  background: rgba(0, 0, 0, 0.05);
  height: 100%;
  width: 235px;
  float: left;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.navbar-brand {
  display: block;
  text-decoration: none;
}
.navbar-brand i {
  font-size: 27px;
  position: relative;
  top: 3px;
  float: left;
  color: #fff;
  margin: 13px 8px 0 14px;
  display: inline-block;
}
.nav-right > a {
  margin: 17px 0px 0px 10px;
  display: inline-block;
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
}
.stic .nav-right > a {
  transform: rotate(360deg);
}
.nav-right > a i {
  color: #fff;
  font-size: 25px;
}
.sidebar {
  width: 235px;
  /*top: 60px;*/
  float: left;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  position: fixed;
  height: 100%;
  background: #fff;
  overflow: visible;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  z-index: 2;
}
.user-info {
  height: 130px;
  position: relative;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  margin-top: 60px;
}
.user-sb-name {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  bottom: 0px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.user-info > img {
  margin: 20px;
  width: 53px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  /*margin: 35px 20px;*/
}
.user-sb-name > div > a {
  color: #fff;
  font-size: 13px;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-weight: bold;
  position: relative;
  padding: 8px 5px;
  display: block;
  cursor: pointer;
}
.user-sb-name > div > a:after {
  /*content: ">";*/
  position: absolute;
  right: 10px;
  transform: rotate(90deg);
  font-family: monospace;
}
.navbar > ul > li {
  position: relative;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.navbar > ul > li > a {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #607d8b;
  padding: 8px 10px 8px 10px;
  display: block;
  font-size: 15px;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
}
.navbar > ul > li > a > i {
  width: 27px;
  height: 27px;
  font-size: 21px;
  line-height: 27px;
  display: inline-block;
  vertical-align: middle;
  color: #99abb4;
  margin-top: -3px;
}
.navbar ul li b {
  box-shadow: 0 0px 8px 3px rgba(241, 89, 40, 0.41);
  height: 6px;
  width: 6px;
  background: #c2263f;
  border-radius: 50%;
  float: right;
  position: absolute;
  left: 30px;
  top: 5px;
}
.navbar > ul > li > a.active {
  font-weight: 500;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  /*border-radius: 0 30px 30px 0;*/
  color: #fff !important;
}
.navbar > ul > li > a.active > i {
  color: #fff !important;
}
.navbar > ul > li > a:hover {
  background: #f6f6f6;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #669295;
}
.navbar > ul > li > a:hover i {
  color: #669295;
}
.page {
  margin: 75px 0 0 255px;
  float: left;
  min-height: 454px;
  width: calc(100% - 270px);
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  padding-bottom: 50px;
}
.nopagemargin .page {
  margin: 0;
  width: 100%;
}
.cont {
  width: 100%;
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 10px;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}
.stic .navbar-header,
.stic .sidebar {
  width: 60px;
}
.stic .navbar > ul {
  width: 50px;
  margin-left: 5px;
  margin-top: 5px;
}
.stic .navbar > ul > li > a > i {
  width: 40px;
  height: 27px;
  font-size: 23px;
  line-height: 27px;
  margin-left: 3px;
}
.stic .navbar > ul > li > a span {
  display: none;
}
.stic .user-info > img {
  margin: 4px;
  width: 50px;
}
.stic .user-sb-name {
  display: none;
}
.stic .user-info {
  height: 60px;
}
.stic .page {
  margin: 75px 0 0 75px;
  width: calc(100% - 90px);
}
.stic .navbar > ul > li:hover span {
  display: inline-block;
  text-align: left;
}
.stic .navbar > ul > li:hover {
  /*width: 240px;*/
}
.stic .navbar > ul > li:hover > a {
  background: #669295;
  color: #fff;
}
.stic .navbar > ul > li:hover > a i {
  color: #fff;
}
.btn-gradient-secondary {
  border-radius: 2rem;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff4137),
    color-stop(51%, #fdc800),
    to(#ff4137)
  );
  background-image: -webkit-linear-gradient(
    left,
    #ff4137 0,
    #fdc800 51%,
    #ff4137 100%
  );
  background-image: -moz-linear-gradient(
    left,
    #ff4137 0,
    #fdc800 51%,
    #ff4137 100%
  );
  background-image: -o-linear-gradient(
    left,
    #ff4137 0,
    #fdc800 51%,
    #ff4137 100%
  );
  background-image: linear-gradient(
    to right,
    #ff4137 0,
    #fdc800 51%,
    #ff4137 100%
  );
  background-position: left center;
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  border: 0;
  -webkit-box-shadow: 0 0 16px 0 rgba(255, 65, 55, 0.5);
  box-shadow: 0 0 16px 0 rgba(255, 65, 55, 0.5);
  font-size: 13px;
  color: #fff;
  padding: 12px 30px;
  outline: 0 !important;
  font-family: "Montserrat-Medium";
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: bold;
}
.fl-r {
  float: right;
}
.fl-l {
  float: left;
}
.btns {
  float: right;
  position: relative;
  background: linear-gradient(45deg, #d37873 0%, #c96e6e 47%, #c16a6a 100%);
  border: none;
  display: block;
  padding: 9px;
  line-height: 22px;
  /*height: 22px;
    width: 22px;*/
  text-align: center;
  margin: 0;
  -webkit-box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  -moz-box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  cursor: pointer;
  margin-right: 5px;
  color: #fff !important;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 20px;
  border-radius: 4px;
}
.btni {
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: linear-gradient(45deg, #d37873 0%, #c96e6e 47%, #c16a6a 100%);
  border-color: #5867dd;
  border: none;
  color: #fff;
  -webkit-box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  -moz-box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  border-radius: 4px;
}
.btni:disabled {
  cursor: inherit !important;
  opacity: 0.5;
}
.setting-nav .btni {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 2px 10px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 2px 10px 0 rgba(000, 000, 000, 0.41);
}
.setting-nav .btni.active {
  opacity: 0.7;
}
.btni i {
  padding-right: 2px;
  font-size: 16px;
}
.btns:disabled {
  opacity: 0.5;
  cursor: default;
}
.btns:hover,
.btni:hover {
  box-shadow: none;
}
.btn-gradient-secondary:hover {
  background-position: right center;
}
*[data-tooltip] {
  position: relative;
}

*[data-tooltip]::after {
  content: attr(data-tooltip);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  font-size: 12px !important;
  line-height: 16px;
  padding: 2px 5px;
  border: 2px solid #669295;
  display: block;
  position: absolute;
  z-index: 2;
  background: #f6f6f6;
  color: #2b2b2b;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2);
}
*[data-tooltip]:hover::after {
  opacity: 1;
}
.filter-div *[data-tooltip]::after {
  top: 40px;
}
.squerTooltipe {
  height: 7px;
  width: 7px;
  background: #669295;
  display: inline-block;
  margin-bottom: 1px;
}
.icon-right {
  float: right;
  margin-right: 20px;
}
.icon-right a {
  display: inline-block;
  margin: 16px 10px;
  position: relative;
}
.icon-action {
  -webkit-animation: pulsing 0.5s infinite;
  animation: pulsing 0.5s infinite;
}
.bpmItemDelete {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #c2263f;
  height: 100%;
  padding: 0 4px;
  line-height: 81px;
  font-size: 17px;
  color: #fff;
  opacity: 0.5;
  display: none !important;
}
.bpmItemDelete:hover {
  opacity: 1;
}
.fLiftIt:hover .bpmItemDelete {
  display: block !important;
}
@-webkit-keyframes pulsing {
  0% {
    transform: rotate(0deg);
  }
  12% {
    transform: rotate(5deg);
  }
  24% {
    transform: rotate(10deg);
  }
  36% {
    transform: rotate(5deg);
  }
  48% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  72% {
    transform: rotate(-10deg);
  }
  84% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.icon-right a i {
  color: #fff;
  font-size: 22px;
}
.icon-action:before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  background: red;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  box-shadow: inset 0 0 1em red, 0 0 1em red;
}
.right-modal {
  width: 700px;
  position: fixed;
  background: #fff;
  height: 100%;
  top: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  right: -700px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 51;
}
.fullwidthmodal {
  position: fixed;
  background: #fff;
  height: 100%;
  top: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  right: -100%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 51;
  width: calc(100% - 235px);
}
.myMessage a {
  color: #fff;
  text-decoration: underline;
}
.r-open {
  right: 0px !important;
  max-width: 100% !important;
}
.rmod-header {
  height: 60px;
  color: #fff;
  position: relative;
  background: rgba(98, 160, 144, 1);
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  background-position: right center;
}
.rmod-header b {
  font-size: 18px;
  margin: 19px;
  display: inline-block;
}
.rmod-close {
  position: absolute !important;
  right: 70px;
  top: 12px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 0 !important;
}
.rmod-close i {
  font-size: 30px !important;
  color: #fff;
}
.rmod-close:hover {
  transform: rotate(-90deg);
}
.rmod-ok {
  background: rgba(000, 000, 000, 0.4);
  height: 60px;
  width: 60px;
  position: absolute !important;
  right: 0px;
  top: -60px;
  box-shadow: -3px 0 8px 0 rgba(66, 91, 118, 0.21);
  float: right;
  border: none;
  padding: 0px;
  cursor: pointer;
  margin: 0 !important;
}
.rmod-ok:disabled {
  opacity: 0.5;
  cursor: default;
}
.rmod-ok i {
  color: #fff;
  font-size: 25px !important;
  margin: 15px 19px;
  display: block;
}
.togglelist {
  position: relative;
}
.togglelist ul {
  position: absolute;
  background: none;
  right: 0px;
  top: 0px;
  display: none;
  z-index: 9;
}
.togglelist ul a,
.togglelist ul button {
  color: #fff;
  background: #669295;
  padding: 10px 25px;
  display: block;
  border: none;
  border-top: 1px solid #fff;
  width: 100%;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.togglelist ul button:disabled {
  opacity: 0.5;
  cursor: default;
}
.togglelist ul a:hover {
  background: #81bcae;
}
.togglelist ul i {
  font-size: 20px;
  padding-right: 3px;
}
.togglelist:hover ul {
  display: block;
}
.c12,
.c9,
.c8,
.c6,
.c4,
.c3,
.c2 {
  float: left;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.c12 {
  width: 100%;
}
.c9 {
  width: 75%;
}
.c8 {
  width: 66.6%;
}
.c6 {
  width: 48%;
  margin: 1%;
}
.c4 {
  width: 33.3%;
}
.c3 {
  width: 25%;
}
.c2 {
  width: 20%;
}
.sbform label {
  font-size: 12px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-top: 8px;
  font-family: "Montserrat", sans-serif;
  color: #8c8c8c;
  font-weight: 500;
  display: block;
}
.sp-table label {
  margin: 10px;
}
.sp-table b {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.mod-cont {
  padding: 5px 15px;
}
.sbform textarea {
  max-width: 100%;
  background-clip: padding-box;
  border: 1px solid #dedede;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  background: #f9f9f9;
  outline: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
}
.sbform select,
.sbform input[type="text"],
.sbform input[type="tel"],
.sbform input[type="password"],
.sbform input[type="date"],
.sbform input[type="withColor"],
.sbform input[type="time"],
.sbform input[type="email"],
.sbform input[type="phone"],
.sbform input[type="number"],
.sbform input[type="url"],
.sbform .selectstyle__control,
.sbform input[type="multiple"] {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 8px 10px;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  color: #2b2b2b;
  outline: none !important;
  height: 35px;
  border-radius: 4px;
  font-weight: 400;
}
.sbform select:disabled {
  opacity: 0.7;
}
.sbform .selectstyle__control {
  padding: 0px;
  height: inherit;
  border-radius: 0px;
}
.selectstyle__menu * {
  font-size: 13px !important;
}
.selectstyle__menu > div > div:hover {
  background: #669295 !important;
  color: #fff;
}
.sbform .selectstyle__indicators {
  display: none;
}
.sbform .selectstyle__control .selectstyle__value-container {
  padding: 0px !important;
  /* height: 40px; */
  display: unset;
}
.sbform .selectstyle__multi-value {
  float: left;
}
.selectstyle__input {
  max-height: 20px;
}
.selectstyle__input input {
  max-height: 15px;
}
/*::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder  {
    color: rgba(180, 180, 180, 0.62) !important;
    font-family: 'Montserrat-Light' !important;
    font-size: 11px !important;
}*/
input::-webkit-input-placeholder {
  color: rgba(34, 34, 34, 0.62) !important;
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  text-align: left;
}
input:-ms-input-placeholder {
  color: rgba(34, 34, 34, 0.62) !important;
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  text-align: left;
}
input::-ms-input-placeholder {
  color: rgba(34, 34, 34, 0.62) !important;
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  text-align: left;
}
input::placeholder {
  color: rgba(34, 34, 34, 0.62) !important;
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  text-align: left;
}
.pull-up {
  background: #fff;
  min-height: 30px;
  width: 100%;
  -webkit-box-shadow: 0 0 27px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.2);
}
.pull-up .custom-checkbox {
  margin: 0 auto;
}
.pull-up:nth-child(2n + 1) {
  background: #f6f6f6;
}
.pull-up:hover {
  outline: 2px solid #669295;
}
.pull-up:hover td {
  background: #fff !important;
}
.pull-up td {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.pull-up td:last-child {
  overflow: inherit;
}
.contact-page {
  margin-top: 15px;
}
.contact-page h4 {
  display: block;
  margin: 22px 0 0 10px;
  font-weight: bold;
  font-size: 20px;
  font-family: "Montserrat-Bold";
  color: #484848;
}
.contact-page table {
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: inherit;
}
img {
  max-width: 100%;
}
table td,
table th {
  padding: 8px;
  vertical-align: middle;
  font-size: 13px;
  color: #2b2b2b;
  line-height: 18px;
}
.pull-up .img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fff;
  background: #669295;
  display: block;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
}
.pull-up .img img {
  border-radius: 50%;
}
.pull-up .is {
  float: right;
  position: relative;
  background: #d37873;
  border: none;
  display: block;
  font-size: 16px;
  line-height: 34px;
  height: 34px;
  width: 34px;
  text-align: center;
  margin: 0;
  -webkit-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  cursor: pointer;
  margin-right: 5px;
  color: #fff !important;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px;
}
.pull-up .is i {
  color: #fff;
}
.fst-tr td {
  font-size: 13px;
  color: #707070;
  padding: 0px 8px;
  background: none !important;
}
.pull-up a {
  font-size: 13px;
  color: #669295;
  text-decoration: underline;
  cursor: pointer;
}
.pull-up b {
  font-size: 13px;
  color: #707070;
}
/* Скрываем реальный чекбокс */
.checkbox {
  display: none;
}
/* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
.checkbox-custom {
  position: relative; /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
  width: 15px; /* Обязательно задаем ширину */
  height: 15px; /* Обязательно задаем высоту */
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}
/* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
.checkbox-custom,
.label {
  display: inline-block;
  vertical-align: middle;
}

.plate-tabs {
  display: flex;
  margin-top: 10px;
}
.plate-tabs a {
  padding: 8px 20px;
  box-sizing: border-box;
  font-weight: bold;
  color: #2b2b2b;
  font-size: 15px;
}
.plate-tabs-active {
  border-bottom: 3px solid #669295;
  color: #669295 !important;
}

.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 3px 0 0;
}
.radio + label {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 7px;
  font-size: 13px;
}
.radio:not(checked) {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}
.radio:not(checked) + label {
  position: relative;
  padding: 0 0 0 25px;
}
.radio:not(checked) + label:before {
  content: "";
  position: absolute;
  top: -3px;
  left: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #cdd1da;
  border-radius: 50%;
  background: #fff;
}
.radio:not(checked) + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #669295;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.2s;
}
.radio:checked + label:after {
  opacity: 1;
}
.radio:focus + label:before {
  /* box-shadow: 0 0 0 3px rgba(255,255,0,.7); */
}
.kanban-board-sort-buttons {
  display: flex;
  float: right;
}
.kanban-board-sort-buttons a {
  border-bottom: 2.5px solid #bbbbbb;
  font-size: 11px;
  color: #969696;
  font-weight: bold;
  padding: 5px 10px;
  margin-right: 5px;
  height: 40px;
  box-sizing: border-box;
  line-height: 28px;
  text-transform: uppercase;
}
.kanban-board-sort-buttons-active {
  border-color: #669295 !important;
  color: #669295 !important;
}

.feed__new {
  background: #ffffff;
}
.feed__post {
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 15px 15px 30px 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.feed__post iframe {
  max-width: 100%;
}
.feed__post-right {
  flex-grow: 1;
}
.feed_list {
  margin-top: 10px;
}
.feed__post-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}
.feed__post-picture-name {
  height: 45px;
  width: 45px;
  color: #fff;
  background: #808080;
  display: block;
  line-height: 45px;
  text-align: center;
  font-weight: 700;
  font-family: Montserrat-Light;
  text-transform: uppercase;
  position: relative;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 15px;
}
.feed__post .editBlc {
  box-shadow: none;
  padding: 0;
  margin-top: 5px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.feed__post-header h4 {
  color: #669295;
  font-family: Montserrat-Bold;
  font-weight: bold;
  font-size: 15px;
}
.feed__post-header span {
  font-style: italic;
  color: #a9a9a9;
  font-size: 13px;
  display: block;
  margin-top: 1px;
}
.feed__post-title {
  font-family: Montserrat-Black;
  font-size: 24px;
  margin-top: 10px;
  color: #4c4c4c;
}
.feed__hr {
  border-top: 2px solid #669295;
  width: 100%;
  margin-bottom: 0;
}
.feed__hr + b {
  transform: rotate(-90deg);
  display: block;
  font-size: 35px;
  height: 0px;
  width: 0px;
  margin: 20px auto;
  position: relative;
  left: -15px;
  color: #669295;
  cursor: pointer;
}

.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  left: 47%;
  top: 50%;
}
.multiselect .gd__control {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 10px 10px;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  height: 40px;
}
.loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #669295;
  border-color: #669295 transparent #669295 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
.checkbox:checked + .checkbox-custom::before {
  content: ""; /* Добавляем наш псевдоэлемент */
  display: block; /* Делаем его блочным элементом */
  position: absolute; /* Позиционируем его абсолютным образом */
  /* Задаем расстояние от верхней, правой, нижней и левой границы */
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: #669295; /* Добавляем фон. Если требуется, можете поставить сюда картинку в виде "галочки", которая будет символизировать, что чекбокс отмечен */
  border-radius: 2px;
}
.label-st {
  background: #ffc164;
  padding: 3px 10px;
  line-height: 13px;
  color: #ffffff;
  font-weight: 400;
  font-size: 75%;
  -webkit-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  margin: 3px;
  display: inline-block !important;
  border-radius: 4px;
}
.datger {
  background: #c2263f;
}
.toogle-bottom {
  position: relative;
  display: inline-block;
}
.user-sb-name .toogle-bottom {
  width: 100%;
}
.toogle-list {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 175px;
  right: 0;
  top: 37px;
  display: none;
  z-index: 2;
  width: max-content;
  border-radius: 4px;
}
.toogle-bottom:hover .toogle-list {
  display: block;
}
.toogle-list li {
  padding: 9px 10px;
  position: relative;
}
.toogle-list li:hover {
  background: #669295 !important;
}
.toogle-list li:hover a {
  color: #ffffff;
}
.toogle-list li:hover b {
  color: #fff;
}
.toogle-list li:last-child {
  border: none;
}
.toogle-list a {
  margin: 0px;
  color: #2b2b2b;
  display: block;
  text-align: left;
}
.toogle-list a:hover {
  color: #ffffff;
}
.toogle-list a b {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #525252;
}
.toogle-list a i {
  color: #c1bfd0;
  font-size: 20px;
  padding-right: 2px;
  position: relative;
  top: 2px;
}
.toogle-list li:hover i {
  color: #fff;
}
.datetime input {
  width: 48%;
}
.datetime input[type="date"] {
  margin-right: 2%;
}
.rem {
  padding: 9px 13px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 0;
  background-color: #669295;
  color: #fff;
  -webkit-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 5px 8px 0 rgba(000, 000, 000, 0.41);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 5px 0 10px 0;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  border: none;
}
.header-search {
  display: inline-block;
}
.header-search input {
  background: rgb(76, 137, 121);
  color: #fff;
  border: none;
  outline: none;
  height: 27px;
  padding: 5px 10px;
  width: 270px;
  position: relative;
  top: -3px;
  margin-right: -45px;
}
.header-search input::-webkit-input-placeholder {
  color: #fff;
}
.sp-table .toogle-list {
  top: 34px;
}
.sp-table .toogle-list a {
  text-decoration: none;
}
.sp-table .toogle-list b {
  font-size: 12px;
}
.sp-table .toogle-list i {
  font-size: 14px;
}
.sp-table .toogle-list li {
  padding: 6px 4px;
}
.modal-center {
  position: fixed;
  background: rgba(000, 000, 000, 0.7);
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.modal-ok {
  width: 400px;
  margin: 0 auto;
  margin-top: 100px;
}
.modal-head {
  height: 60px;
  width: 100%;
  background: rgba(98, 160, 144, 1);
  background: -moz-linear-gradient(
    left,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62a090', endColorstr='#1b5c4c', GradientType=1 );
  background-position: right center;
  position: relative;
}
.modal-head b {
  font-family: "Montserrat-Black";
  color: #fff;
  display: block;
  font-size: 18px;
  padding: 19px;
  font-weight: normal;
}
.modal-head-close {
  position: absolute;
  color: #fff;
  font-size: 29px;
  right: 12px;
  top: 14px;
}
.modal-body {
  background: #fff;
  padding: 5px 30px 15px 30px;
}
.modal-body label {
  margin-top: 5px;
}
.modal-body select {
  padding: 6px;
}
.modal-body .btni {
  margin: 5px 0;
  display: inline-block;
}
.sortable {
  font-size: 13px;
  display: block;
  float: left;
  margin: 11px;
}
.sortable select {
  background: none;
  border: none;
  color: #669295;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  cursor: pointer;
}
.fltr .rmod-close {
  right: 15px;
}
.isif select {
  width: 40%;
}
.isif input {
  width: 49%;
}
.isif button {
  width: 42px;
  background: #669295;
  color: #fff;
  font-size: 20px;
  height: 40px;
  border: none;
  cursor: pointer;
  text-align: center;
  -webkit-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
}
.modal-body i {
  font-size: 75px;
  display: block;
  text-align: center;
  padding: 20px 0px;
}
.modal-body h2 {
  font-size: 20px;
  text-align: center;
  padding: 0 0 10px 0;
  font-family: "Montserrat-Medium";
  color: #2b2b2b;
}
.modal-body a {
  color: #828282;
  font-size: 15px;
  padding: 10px 20px;
  border: 1px solid #828282;
  border-radius: 0px;
  display: inline-block;
  margin: 5px 0;
}
.block-but {
  display: table;
  margin: 0 auto;
  padding: 5px 0px;
}
.cred {
  color: #c2263f;
}
.cgreen {
  color: #669295;
}
.modal-body .m-left {
  margin-right: 10px;
}

.modal-body .m-red {
  color: #fff;
  background: #c2263f;
  border: none;
}
.modal-body .m-save {
  background: #669295;
  border: none;
  color: #fff;
}
.mr5 {
  margin-right: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.mr20 {
  margin-right: 20px;
}
.scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #669295;
  border-radius: 4px;
}
.m-list-timeline__group {
  margin: 0 0 30px;
}
.m-list-timeline__heading {
  margin: 0 0 18px;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}
.m-list-timeline__items {
  position: relative;
  padding: 0;
  margin: 0;
}
.m-list-timeline__items:before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 3px;
}
.m-list-timeline__items .m-list-timeline__item {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 6px 0;
  margin: 5px 0;
}
.m-list-timeline__items .m-list-timeline__item:first-child:before,
.m-list-timeline__items .m-list-timeline__item:last-child:before {
  background-color: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 50%;
  top: 0;
  bottom: 0;
  left: 3px;
}
.m-list-timeline__items .m-list-timeline__item:last-child:before {
  top: auto;
  bottom: 0;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__badge {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  width: 20px;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__badge:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  left: 0;
  top: 50%;
  margin-top: -3.5px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__icon {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  width: 30px;
  padding: 0 5px 0 0;
  line-height: 0;
}
.m-list-timeline__items .m-list-timeline__item [class*=" la-"],
.m-list-timeline__items .m-list-timeline__item [class^="la-"] {
  font-size: 1.6rem;
}
.m-list-timeline__items .m-list-timeline__item [class*=" fa-"],
.m-list-timeline__items .m-list-timeline__item [class^="fa-"] {
  font-size: 1.2rem;
}
.m-list-timeline__items .m-list-timeline__item [class*=" flaticon-"],
.m-list-timeline__items .m-list-timeline__item [class^="flaticon-"] {
  font-size: 1.6rem;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__text {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  padding: 0 5px 0 0;
  font-size: 1rem;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__text:hover {
  text-decoration: none;
}
.m-list-timeline__items .m-list-timeline__item .m-list-timeline__time {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  width: 80px;
  padding: 0 7px 0 0;
  font-size: 0.85rem;
}
.m-list-timeline__items .m-list-timeline__item:first-child {
  padding-top: 0;
  margin-top: 0;
}
.m-list-timeline__items .m-list-timeline__item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.m-list-timeline .m-list-timeline__items:before {
  background-color: #ebedf2;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge:before {
  background-color: #ebedf2;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--brand:before {
  background-color: #716aca;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--metal:before {
  background-color: #c4c5d6;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--light:before {
  background-color: #fff;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--accent:before {
  background-color: #00c5dc;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--focus:before {
  background-color: #9816f4;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--primary:before {
  background-color: #5867dd;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--success:before {
  background-color: #34bfa3;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--info:before {
  background-color: #36a3f7;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--warning:before {
  background-color: #ffb822;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__badge.m-list-timeline__badge--danger:before {
  background-color: #f4516c;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__icon {
  color: #cfcedb;
  color: #2b2b2b;
  font-size: 22px;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__text {
  color: #575962;
  font-size: 13px;
  line-height: 18px;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__time {
  color: #7b7e8a;
  font-size: 11px;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item:first-child:before,
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item:last-child:before {
  background-color: #fff;
}
.m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item.m-list-timeline__item--read {
  opacity: 0.5;
}
.m-list-timeline.m-list-timeline--skin-dark .m-list-timeline__items:before {
  background-color: #a1a4b1;
}
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__icon {
  color: #dfdff1;
}
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__text {
  color: #fff;
}
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__time {
  color: #8284a1;
}
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item:first-child:before,
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item:last-child:before {
  background-color: #333;
}
.m-list-timeline.m-list-timeline--skin-dark
  .m-list-timeline__items
  .m-list-timeline__item.m-list-timeline__item--read {
  opacity: 0.5;
}

.m-badge {
  font-size: 11px;
  line-height: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  /*-webkit-border-radius: .75rem;
    -moz-border-radius: .75rem;
    -ms-border-radius: .75rem;
    -o-border-radius: .75rem;
    border-radius: .75rem;*/
  letter-spacing: 0.6px;
  padding: 0px 10px;
  background-color: #669295;
  color: #fff;
  text-transform: lowercase;
}
.m-demo a {
  text-decoration: underline;
  color: #669295;
  font-size: 13px;
}
.phone-numb input {
  width: 100%;
}
.phone-numb {
  position: relative;
}
.phone-numb button {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  background: #669295;
  color: #fff;
  font-size: 20px;
  height: 35px;
  border: none;
  cursor: pointer;
  text-align: center;
  -webkit-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  -moz-box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
}
.call-body .m-list-timeline__text em {
  font-style: italic;
  display: block;
}
.contact-page .contact-left-col {
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 15px;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}
.contact-left {
  width: 25%;
  float: left;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}

.contact-page .contact-right-col {
  width: 74%;
  float: right;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}
.contact-page .tab-content {
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flow-root;
  border-radius: 4px;
}
.contact-page h3 {
  text-align: center;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: #484848;
  padding-top: 10px;
}
.fgroup {
  position: relative;
}
.fgroup i {
  position: absolute;
}
.clearFieldPadding .fgroup {
  padding: 0 5px;
}
.rc1,
.rc2,
.rc3,
.rc4,
.rc6,
.rc8,
.rc9,
.rc12 {
  box-sizing: border-box;
  float: left;
  padding: 5px;
}
.rc1 {
  width: 8.33%;
}
.rc2 {
  width: 16.6%;
}
.rc3 {
  width: 25%;
}
.rc4 {
  width: 33.3%;
}
.rc6 {
  width: 50%;
}
.rc8 {
  width: 66.6%;
}
.rc9 {
  width: 75%;
}
.rc12 {
  width: 100%;
}
.tags {
  display: table;
  margin: 0 auto;
  padding-top: 10px;
}
.add-tag {
  width: 22px;
  height: 22px;
  background: #669295;
  display: inline-block;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-family: "Montserrat-Bold";
  font-size: 18px;
}
.tag {
  background: #669295;
  color: #fff;
  display: inline-block;
  border-radius: 25px;
  padding: 3px 12px;
  margin: 0 4px;
  font-size: 13px;
}
.tabs-header li {
  display: inline-block;
  background: #fff;
  margin: 0 2px 5px 0;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 3px;
}
.tabs-header li a {
  font-size: 13px;
  color: #2b2b2b;
  padding: 7px 13px;
  display: block;
}
.tabs-header li a:hover,
.tabs-header li a.active {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact-chronology .m-list-timeline__text {
  font-size: 14px !important;
}
.contact-chronology .m-list-timeline__items .m-list-timeline__item {
  margin: 8px 0 !important;
}
.contact-chronology .m-list-timeline__text b {
  font-weight: bold;
  font-family: "Montserrat-Medium";
}
.contact-chronology
  .m-list-timeline
  .m-list-timeline__items
  .m-list-timeline__item
  .m-list-timeline__icon {
  font-size: 25px !important;
}
.download-tab i {
  font-size: 20px;
  color: #2b2b2b;
}
.download-tab span {
  display: block;
  margin: 3px;
}
.download-tab img {
  float: left;
  margin-right: 5px;
  /*max-width: 25px;*/
}
.download-tab td {
  border-bottom: 1px solid #bbb;
}
.download-tab .fst-tr td {
  border: none;
}
.download-tab table {
  border-spacing: 0;
}
.download-tab tr {
  box-shadow: none !important;
}
.product-tab .sp-table input {
  padding: 5px;
  height: 30px !important;
}
.serch-product {
  width: 300px;
  float: left;
  margin-bottom: 10px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
}
.serch-product input {
  float: left;
  width: 100%;
}
.search-product-lead .AutoSelectParent__child {
  top: 5px !important;
}
.serch-button.btn-m {
  padding: 9px;
  margin: 0px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
}
.mailsearch-l select {
  font-size: 16px;
  height: inherit;
  border: none;
  color: #2b2b2b;
  font-weight: bold;
  margin-top: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.mailsearch {
  height: 50px;
  /*background: #eaedef;*/
  margin-top: 15px;
}
.pnav-right,
.pnav-left {
  height: 30px;
  width: 30px;
  display: inline-block;
  text-align: center;
  line-height: 29px;
  color: #2b2b2b;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  background: #fff;
  font-family: monospace;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.15);
  border: none;
  cursor: pointer;
}
.pnav-right:disabled,
.pnav-left:disabled {
  opacity: 0.3;
  cursor: default;
}
.pnav-right {
  border-radius: 0 20px 20px 0;
}
.pnav-left {
  border-radius: 20px 0 0 20px;
}
.mailsearch-r {
  width: 300px;
  float: right;
  text-align: right;
  padding-right: 10px;
}
.mailsearch-r span {
  font-size: 15px;
  color: #2b2b2b;
  font-weight: bold;
  padding-right: 10px;
  display: inline-block;
}
.pnav-left:hover,
.pnav-right:hover {
  background: #669295;
  color: #fff;
}
.mailleft {
  width: 30%;
  border-right: 1px solid #e5e5e5;
  box-sizing: border-box;
  float: left;
}
.email-who-block {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  padding: 10px 10px 10px 30px;
  display: block;
  color: inherit;
  position: relative;
  overflow: hidden;
}
.email-who-block:hover,
.email-who-block.active {
  background: #fff;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
  /* border-right: 5px solid #669295; */
}
.email-who-block:hover:after,
.email-who-block.active:after {
  display: block !important;
}
.email-who-block:after {
  content: "";
  display: none;
  height: 100%;
  width: 5px;
  background: #669295;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 0px 20px rgba(98, 160, 145, 0.4);
  position: absolute;
  right: -1px;
  top: 0px;
}
.email-who-block:before {
  content: "";
  background: #a0a0a0;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  left: 10px;
  top: 34px;
  border-radius: 50%;
}
.isread:before {
  display: none !important;
}
.mail-avatar {
  float: left;
  margin-right: 10px;
}
.mail-avatar span {
  height: 45px;
  width: 45px;
  line-height: 45px;
  display: block;
  background: #669295;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  margin-top: 6px;
  -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
}
.mail-who b {
  font-weight: bold;
  font-family: "Montserrat-Medium";
  font-size: 15px;
  color: #2b2b2b;
}
.mail-who h3 {
  color: #949494;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 7px;
}
.mail-who em {
  color: #949494;
  font-size: 13px;
  font-style: italic;
}
.mailbody {
  width: 100%;
  box-sizing: border-box;
  float: left;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.wrap-h {
  font-size: 18px;
  color: #2b2b2b;
  margin: 0;
  font-family: "Montserrat-Medium";
  max-width: 600px;
  font-weight: bold;
}
.mailsearch-l {
  float: left;
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
}
.mailsearch-l select {
  background: none;
}
.mail-body-time {
  font-size: 13px;
  color: #949494;
  font-weight: bold;
  font-style: italic;
  padding-bottom: 20px;
}
.mailbody p {
  font-size: 14px;
  color: #2b2b2b;
  padding: 3px 0;
  font-weight: bold;
}
.body-headers {
  position: relative;
}
.mail-body-todo {
  position: absolute;
  right: 0px;
  top: 0px;
}
.mail-body-todo a {
  color: #7e7e7e;
  font-size: 18px;
  margin-left: 7px;
}
.mail-btn a {
  background: #669295;
  font-size: 16px;
  padding: 12px 21px;
  display: inline-block;
  margin: 3px 0;
  color: #fff;
  border: none;
  /*border-radius: 32px;*/
  font-family: "Montserrat-Medium";
  margin-top: 20px;
  margin-right: 10px;
}
.mail-btn a i {
  font-size: 18px;
  margin-left: 5px;
}
.mail-files {
  border-top: 2px solid #e5e5e5;
  margin-top: 5px;
  padding: 5px 0;
}
.mail-files-em {
  font-size: 13px;
  color: #646464;
  font-weight: bold;
  display: block;
  margin-top: 20px;
}
.mail-files img {
  margin-top: 3px;
  max-width: 50px;
  display: inline-block;
}
.mail-inbox table {
  border-spacing: 0px;
}
.mail-inbox table td {
  padding: 4px;
}
.mail-inbox table td strong {
  font-weight: bold;
  font-family: "Montserrat-Medium";
  font-size: 13px;
}
.mail-inbox table td a {
  text-decoration: none;
  color: inherit;
  font-size: 12px;
  width: 100%;
  display: block;
  overflow: hidden;
}
.fltr .toogle-list {
  top: 30px;
}
.fltr .toogle-list a,
.fltr .toogle-list b {
  text-decoration: none;
  font-size: 13px;
}
.switch {
  position: relative;
  display: inline-block !important;
  width: 44px !important;
  height: 26px !important;
  padding: 0px !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #669295;
}
.mclose {
  color: #fff;
  font-size: 30px;
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 12px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.save .mclose {
  color: #fff;
  font-size: 30px;
  position: absolute;
  z-index: 1;
  right: 70px;
  top: 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.save .mclose:hover {
  transform: rotate(-90deg);
}
.backdrop {
  z-index: 50;
  background: rgba(000, 000, 000, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.table-prv td {
  padding: 8px;
  font-weight: 300;
  font-size: 14px;
  line-height: 8px;
  text-align: center;
}
.table-prv tr:nth-child(2n) {
  background: rgba(199, 199, 199, 0.15);
}
.noshadow tr {
  box-shadow: none;
}
.errorMsg {
  font-size: 12px;
  color: #f4516c;
  line-height: 14px;
  display: block;
  padding-top: 3px;
  text-align: right;
  font-weight: 400;
}
.fgroup .errorMsg {
  position: absolute;
  right: 3px;
  bottom: -9px;
}
.AutoSelectParent .errorMsg {
  bottom: -15px;
}
.AutoSelectParent {
  position: relative;
}
.AutoSelectParent__child {
  font-size: 17px;
  position: absolute;
  right: 4px;
  bottom: 3px;
  background: #e4e4e4;
  width: 28px;
  height: 28px;
  text-align: center;
  color: #565656;
  transform: rotate(270deg);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px;
}
.AutoSelectParent__no-label .AutoSelectParent__child {
  bottom: -32px;
}
.multiSelect .AutoSelectParent__child {
  display: none !important;
}
.AutoSelectParent__child-active {
  transform: rotate(90deg);
}
.AutoSelectParent__child-novalid {
  /*bottom: 20px;*/
}
.AutoSelectList {
  max-height: 300px;
  position: absolute;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.resize-list {
  bottom: 35px;
}
.AutoSelectList a {
  font-size: 13px;
  color: #575962;
  padding: 7px 8px;
  display: block;
  cursor: pointer;
}
.AutoSelectList a:hover {
  background: #669295;
  color: #fff;
}
.card-block span {
  font-size: 15px;
  color: #2b2b2b;
}
.card-block {
  margin-bottom: 8px;
}
.contactsAddBlock {
  position: relative;
}
.card-block a {
  color: #669295;
  font-size: 15px;
  text-decoration: underline;
}
.card-block em {
  font-size: 13px;
  color: #999999;
  display: block;
}
.lead-form-close {
  position: absolute;
  right: 15px;
  top: 8px;
  color: #2b2b2b !important;
  font-size: 20px !important;
  text-decoration: none !important;
  cursor: pointer;
}
.saveContForm:disabled {
  display: none;
}
.togglebutton {
  position: relative;
  display: inline-block;
}
.toggleAddBlock {
  position: absolute;
  left: 160px;
  top: -2px;
  width: 360px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
}
.serch-button:disabled {
  display: none;
}
.prod-del {
  height: 32px !important;
  width: 32px !important;
  line-height: 32px !important;
}
.prod-del i {
  font-size: 13px !important;
}
.serch-product .AutoSelectList {
  top: 50px;
}
.taskcontainer {
  margin-bottom: 5px;
}
.taskdiv {
  width: 33%;
  margin: 1px;
  font-size: 12px;
  background: #fff;
  padding: 10px;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2);
  display: inline-block;
  box-sizing: border-box;
  height: 70px;
  border-radius: 4px;
}
.taskdiv button {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  color: #fff;
  font-size: 12px;
  padding: 2px 10px;
  margin-top: 4px;
  display: inline-block;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  cursor: pointer;
  border-radius: 4px;
}
.taskdiv a {
  display: block;
  font-size: 12px;
  cursor: pointer;
  height: 30px;
}
.taskdiv em {
  float: right;
  display: inline-block;
  margin-top: 8px;
  color: #5f5f5f;
  padding-right: 2px;
}
.taskdiv a:hover {
  text-decoration: underline;
}
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #669295 !important;
}
.react-datepicker * {
  font-family: "Montserrat", sans-serif !important;
  border-radius: 0 !important;
}
.react-datepicker__header {
  background: #fff !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #669295 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: initial !important;
  padding: 8px 10px !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #444;
}
.react-datepicker__close-icon::after {
  background: none !important;
  color: #444 !important;
  font-size: 19px !important;
}
.react-datepicker__month {
  margin: 0.1rem !important;
  text-align: center;
}
.login {
  background-image: url("https://sboxcrm.com/images/background/login-register.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: fixed;
}
.sidebarLogin {
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
}
.sidebarLogin img {
  max-width: 260px;
  display: block;
  margin: 0 auto;
  padding: 25px 0;
}
.sidebarLogin button {
  background: #71b0a0;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 17px;
  width: 98%;
  padding: 12px 0;
  margin: 1%;
  font-weight: bold;
}
.formlabel label {
  padding-top: 0;
}
.pointer {
  cursor: pointer;
}
.connection {
  padding: 5px;
  margin: 2px 5px;
  border-bottom: 1px solid #b2b2b2;
  clear: both;
  margin-top: 20px;
}
.connection span {
  display: inline-block;
  font-size: 14px;
  color: #669295;
  /*font-weight: bold;*/
  padding-left: 0px;
  text-shadow: 0px 0px 0px black, 0 0 2px #669295;
}
.connection .btni {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  font-size: 20px;
  float: right;
  width: 22px;
  height: 22px;
  line-height: 22px;
  padding: 0px;
  margin-top: -2px;
}
.toggleparrent {
  position: relative;
  display: contents;
}
.toggleparrent ul {
  position: absolute;
  right: 38px;
  padding: 3px;
  border: 1px solid transparent;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  z-index: 1;
  display: none;
  min-width: 140px;
}
.toggleparrent ul ul {
  min-width: 200px;
  right: 140px;
  top: 0px;
  display: none;
}
.toggleparrent ul > li {
  position: relative;
}
.toggleparrent ul > li:hover ul {
  display: block;
}
.toggleparrent ul button {
  background: #fff;
  border: none;
  color: #2b2b2b;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  display: block;
  width: 100%;
  padding: 8px 10px;
  font-size: 13px;
  text-align: center;
}
.toggleparrent ul button:hover {
  background: #669295;
  color: #fff;
}
.toggleparrent:hover > ul {
  display: block;
}
.body-table tbody tr:nth-child(2n + 1) {
  background: #f5f5f5;
}
.body-table a {
  font-size: 13px;
  text-decoration: underline;
}
.body-table .toogle-list a {
  font-size: 13px;
  text-decoration: none;
}
.body-table .is {
  float: right;
  position: relative;
  background: linear-gradient(45deg, #d37873 0%, #c96e6e 47%, #c16a6a 100%);
  border: none;
  display: block;
  font-size: 12px;
  line-height: 25px;
  height: 25px;
  width: 25px;
  text-align: center;
  margin: 0;
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  cursor: pointer;
  margin-right: 5px;
  color: #fff !important;
  text-decoration: none !important;
  transition: all 0.3s ease;
}
.body-table .toogle-list {
  top: 25px;
}
.email-who-block:hover .del {
  right: 4px;
}
.email-who-block .del {
  position: absolute;
  top: 0px;
  height: 100%;
  right: -40px;
  width: 40px;
  background: rgba(194, 38, 63, 0.3);
  z-index: 1;
  display: block;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.email-who-block .del:hover {
  background: rgba(194, 38, 63, 0.8);
}
.email-who-block .del:hover i {
  color: #fff;
}
.email-who-block .del i {
  font-size: 20px;
  color: #2b2b2b;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  display: block;
  line-height: 80px;
}
.mailsearch-l li {
  display: block !important;
  width: 100%;
  position: relative;
}
.mailsearch-l li span {
  position: absolute;
  right: 20px;
  top: 6px;
  background: #669295;
  color: #fff;
  padding: 4px 5px;
  border-radius: 50%;
  font-size: 13px;
}
.mailsearch-l a {
  display: block;
  margin: 0px 2px;
  padding: 10px 13px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  color: #2b2b2b;
  box-sizing: border-box;
  font-weight: bold;
}
.mailsearch-l a.active {
  background: rgba(000, 000, 000, 0.05);
}
.mailsearch-l a:hover {
  background: rgba(000, 000, 000, 0.1);
}
.mailsearch-l i {
  float: left;
  font-size: 17px;
  padding: 0px 8px;
}
.mailsearch-l a:hover {
  box-shadow: none;
}
.ql-editor {
  background: #f9f9f9;
  min-height: 200px;
}
.submenu {
  width: 100%;
  display: none;
  /*right: -260px;
    top:0px;
    background: #fff;
    z-index: 1;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    height: 100%;
    transition: display 1.3s ease;
    display: block;*/
}
.submenu a {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #607d8b;
  padding: 9px 0 9px 34px;
  display: block;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-bottom: 1px solid #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5 !important;
}
.submenu a:hover {
  background: #669295;
  color: #fff;
}
.navbar > ul > li:hover .submenu {
  display: block;
}
.stic .submenu {
  right: 20px;
  width: 165px;
  top: 40px;
}
.stic .submenu a {
  display: block;
  font-size: 13px;
  padding: 10px 10px;
  width: 100%;
}
.stic .submenu a:first-child {
  border-top: 1px solid #fff;
}
.miniTable {
  border-spacing: 0 !important;
}
.miniTable td {
  font-size: 13px;
  padding: 7px;
}
.miniTable tr:nth-child(2n + 1) {
  background: #f7f7f7;
}
.miniTable thead td {
  font-size: 12px;
  padding: 7px 7px 3px 7px;
  color: #8c8c8c;
  font-weight: bold;
}
.notab .tab-content {
  box-shadow: none;
  padding: 0;
}
.react-datepicker__day--keyboard-selected {
  background-color: #669295 !important;
}
.attachmentItem {
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  display: block;
  max-width: 350px;
  color: #2b2b2b;
  font-weight: bold;
  font-size: 13px;
  padding: 8px 8px;
  margin-bottom: 10px;
  background: #fff;
}
.seen0 {
  background: #f3f3f3 !important;
}
.seen0 .img:after {
  content: "";
  display: block;
  height: 8px;
  width: 8px;
  background: #626968;
  border-radius: 50%;
  right: -18px;
  top: 17px;
  position: absolute;
}
.seen0 td {
  font-weight: bold;
  font-family: "Montserrat-Medium";
}
.seen1 {
  background: #fff !important;
}
.seen1 strong,
.seen1 td {
  font-weight: normal !important;
  font-family: "Montserrat-Light" !important;
}
table tbody tr:nth-child(2n) .img {
  background: #8f6bc3;
}
/*table tbody tr:nth-child(3n) .img {
    background: #fbd753;
}*/
table tbody tr:nth-child(3n) .img {
  background: #3eaff4;
}
table tbody tr:nth-child(4n) .img {
  background: #fc8a80;
}
table tbody tr:hover td {
  background: #ebebeb;
}
.letterListFileItem {
  max-width: 370px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  font-size: 13px;
  margin: 8px 0;
  position: relative;
}
.letterListFileItem a {
  background: #c2263f;
  position: absolute;
  color: #fff;
  text-align: center;
  right: 0;
  height: 35px;
  line-height: 35px;
  top: 0;
  font-size: 13px;
  display: none;
  padding: 0 12px;
}
.letterListFileItem i {
  padding-right: 7px;
  font-size: 16px;
}
.letterListFileItem:hover a {
  display: block;
}
.btn-m:disabled {
  display: none;
}
.mail-contact i {
  font-size: 25px;
  color: #7e7e7e;
}
.mail-contact {
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  padding: 10px 10px;
  margin-top: 10px;
  background: #fff;
}
.mail-contact span {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: -4px;
  left: 7px;
}
.mail-contact b {
  display: block;
  text-align: center;
  font-family: "Montserrat-Black", sans-serif;
  font-weight: bold;
  padding: 2px;
  color: #2b2b2b;
}
.mail-contact .success {
  display: block;
  text-align: center;
  margin: 7px;
}
.distext:disabled {
  display: none;
}
.sp-table .label-st {
  color: #000;
  font-weight: 500;
}
.sp-table .label-st:hover {
  background: #669295 !important;
  color: #fff;
}
.failreason:hover {
  background: #c2263f !important;
}
.producttable input {
  border: none !important;
  background: none !important;
  border-bottom: 1px solid #c0c0c0 !important;
  padding: 0 !important;
  width: inherit !important;
  font-size: 13px !important;
}
.producttable td {
  font-size: 13px;
}
.producttable input:focus {
  border-bottom: 1px solid #669295 !important;
}
.rsb {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  padding: 8px 10px;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #2b2b2b;
  outline: none !important;
  /* height: 35px;*/
  position: relative;
  min-height: 35px;
  border-radius: 4px;
}
.rsb a {
  font-size: 13px;
  text-decoration: underline;
}

.rsb .unlink {
  color: #c2263f;
  text-decoration: none;
  position: absolute;
  right: 8px;
  font-weight: bold;
  font-size: 20px;
  margin-top: -6px;
  margin-right: -2px;
}
.parrentBlock {
  position: relative;
}
.childBlock {
  position: absolute;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  background: #fff;
}
input:disabled:after {
  content: "";
  height: 20px;
  width: 20px;
  display: block;
  /*background: url("http://s1.iconbird.com/ico/2013/9/452/w384h5121380476914lock.png");*/
  position: absolute;
}
.inputParrent {
  position: relative;
}
.inputParrent a {
  position: absolute;
  width: 35px;
  height: 35px;
  right: 0px;
  z-index: 2;
  text-align: center;
  line-height: 35px;
  font-size: 19px;
  display: none;
}
.inputParrent:hover a {
  display: block;
}
.inputParrent i {
  position: initial !important;
}

.inputParrent a:after {
  right: 0px;
  min-width: 200px;
}
.analitics {
  overflow: hidden;
}
.analitics * {
  font-family: "Montserrat-Light" !important;
}
.styleGrath {
  height: 150px;
  width: 150px;
  overflow: hidden;
}
.col3 {
  padding: 20px;
  width: 24.5%;
  background: #fff;
  position: relative;
  height: 113px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  float: left;
  box-sizing: border-box;
  margin: 0.25%;
}
.col4 {
  /*padding: 20px;*/
  width: 33%;
  /*background: #fff;*/
  position: relative;
  /*box-shadow: 0 1px 15px 1px rgba(69,65,78,.1);*/
  float: left;
  box-sizing: border-box;
  margin: 0.25%;
}
.col6 {
  padding: 20px;
  width: 49.5%;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  float: left;
  box-sizing: border-box;
  margin: 0.25%;
}
.col12 {
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin: 15px 0;
}
.col8 {
  width: 66%;
  margin: 0.25%;
  float: left;
  background: #fff;
}
.col3 span {
  font-size: 13px;
  font-style: italic;
  color: #9d9c9c;
}
.col3 h4 {
  font-size: 24px;
  margin-top: 8px;
}
.styleGrath {
  right: 0px;
  position: absolute;
  top: -25px;
}
.SvgjsText1020 {
  font-weight: bold !important;
}
.analitics h2 {
  font-size: 19px;
  margin: 10px 0 -15px 10px;
  color: #2b2b2b;
  text-shadow: 0px 0px 0px black, 0 0 1px #2a2a2a;
}
.color-table {
  width: 100%;
  margin-top: 30px;
}
.color-table thead th {
  background-color: #c2263f;
  color: #ffffff;
  padding: 0.75rem;
}
.color-table td {
  padding: 0.75rem;
}
.color-table tr td {
  text-align: center;
}
.color-table tr th:first-child,
.color-table tr td:first-child {
  text-align: left;
}
.stock {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.stock .stockItem {
  height: 150px;
  width: 150px;
  border: 2px solid #669295;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}
.stock .stockItem div {
  height: 105px;
  display: flex;
  justify-content: center;
}
.stock .stockItem:hover {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.5);
}
.stock .stockItem i {
  font-size: 52px;
  text-align: center;
  display: block;
  line-height: 102px;
}
.stock .stockItem span {
  font-size: 14px;
  text-align: center;
  display: block;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
}

.stocktop {
  display: flex;
  background: #fff;
  height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 0 20px;
  margin-top: -14px;
  margin: -13px auto;
  width: 100%;
  margin-left: -25px;
  margin-bottom: 15px;
}
.stocktop .stockItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 40px;
  vertical-align: middle;
  align-items: center;
  color: #424242;
  padding: 5px 15px;
}
.stocktop .stockItem i {
  font-size: 22px;
  text-align: center;
  display: block;
  margin-right: 6px;
}
.stocktop .stockItem span {
  font-size: 14px;
  text-align: center;
  display: block;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #5d5d5d;
}
.stocktop .stockItem:hover span,
.stocktop .stockItem:hover i {
  color: #669295;
}
.stocktop .stockItem.active span,
.stocktop .stockItem.active i {
  color: #669295;
  opacity: 0.5;
}
.stocktop__scroll {
  display: flex;
  max-width: 80%;
  overflow-x: auto;
  overflow-y: hidden;
}
.stocktop__scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.stocktop__scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.stocktop__scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.overlinered {
  outline: 1px solid #c2263f !important;
}
.overlinered a {
  color: #c2263f;
  font-weight: bold;
}
.body-table thead td {
  font-size: 12px;
  color: #848484;
  padding: 6px 3px 3px 3px;
}
.filterItem {
  font-size: 13px;
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  padding: 8px 10px;
  border: 2px solid #669295;
  margin-right: 4px;
  margin-bottom: 4px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.2);
}
.filterItem em {
  color: #747474;
  font-style: italic;
  font-size: 12px;
}
.filterItem a {
  font-size: 17px;
  display: inline-block;
  font-weight: bold;
  margin-left: 7px;
  margin-top: -3px;
}
.filterBlock select,
.filterBlock input {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #669295 !important;
  height: 40px !important;
}
.filterBlock select {
  padding: 8px 0;
}
.filterBlock .rc3,
.filterBlock .rc1 {
  padding: 2px;
}
.filterBlock .rc4,
.filterBlock .rc6,
.filterBlock .rc2,
.filterBlock .rc8 {
  padding: 2px;
}
.filterBlock .btn-m i {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  color: #669295;
  z-index: 3;
}
.filterBlock .btn-m {
  background: none !important;
  margin-left: -30px !important;
  padding: 10px 0 !important;
  position: relative;
}
.filterBlock .btn-m:hover {
  box-shadow: none !important;
}
.filter-div {
  width: 500px;
  margin-right: 5px;
}
.requiredInput {
  border: 1px solid #f4516c !important;
}
.email-em {
  background: #c2263f;
  padding: 2px 5px;
  color: #fff;
  font-size: 12px;
  margin-left: 3px;
  opacity: 0.6;
}
.parenMultiSelect input {
  width: inherit;
}
.itemContainer {
  float: left;
}
.multiSelect {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 3px;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  display: inline-block;
  min-height: 35px;
}
.itemContainer {
  float: left;
}
.multiSelect input {
  float: left !important;
  border: none !important;
  margin: 0px !important;
  height: inherit !important;
  padding: 5px !important;
  box-sizing: content-box !important;
  //width: 2px !important;
}
.multiSelect .inp {
  display: inline-block;
  height: 20px !important;
}
.multiSelect .AutoSelectList {
  margin-top: 30px;
}
.itemContainerItem {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #fff;
  padding: 4px 7px;
  font-size: 12px;
  display: inline-block;
  margin: 1px;
}
.itemContainerItem .unlink {
  color: #fff;
  margin-left: 6px;
}
.parentSelectRelative {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 3px;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  display: inline-block;
  min-height: 35px;
  position: relative;
  padding-right: 25px;
}
.parentSelectDivIcon {
  position: absolute;
  right: 24px;
  top: 6px;
  font-size: 20px;
  color: #b2b2b2;
}
.parentSelectRelative span {
  display: block;
  padding: 6px;
  color: #2b2b2b;
}
.parentSelectAbsolute li b {
  display: inline-block;
  margin-left: 18px;
  font-size: 14px;
  font-weight: normal;
  color: #2b2b2b;
}
.parentSelectAbsolute {
  background: #fff;
  border: 1px solid #dfdfdf;
  display: none;
  box-sizing: border-box;
}
.parentSelectAbsolute li {
  cursor: pointer;
  position: relative;
  list-style-type: none;
  padding: 6px;
  overflow: hidden;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.parentSelectAbsolute li:hover {
  background: #f7f7f7;
}
.clearButtonStyle {
  background: none;
  text-align: left;
  border: none;
  width: inherit;
  padding: 0;
  outline: none;
  width: 100%;
}
.importtable td {
  border: 1px solid #ededed;
  padding: 10px;
}
.importtable {
  background: #fff;
  width: fit-content;
  padding: 10px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
}
.pStyle {
  font-size: 13px;
  color: #2b2b2b;
  max-width: 800px;
}
.pStyle a {
  text-decoration: underline;
}
.sbform h2 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}
.analiticsIcon i {
  font-size: 45px;
  position: absolute;
  right: 20px;
  top: 29px;
  color: #669295;
}
.basic-table-color th {
  background: #669295 !important;
}
.basic-table-color td,
.danger-table-color td {
  text-align: center;
}
.basic-table-color td:first-child,
.danger-table-color td:first-child {
  text-align: left;
}
.basic-table-color tbody tr:nth-child(2n + 1) {
  background: #fff;
}
.danger-table-color th {
  background: #ffb22b !important;
  color: #2b2b2b !important;
}
.color-table tbody tr:nth-child(2n) {
  background: #f6f6f6;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
}
.danger-table-color tbody tr:nth-child(2n + 1) {
  background: #fff;
}
div#apexchartsytun6ieh * {
  text-shadow: 0px 0px 1px black, 0 0 1px black;
}
.salesBlock {
  background: #669295;
  color: #fff;
  padding: 20px 15px;
  background: linear-gradient(#669295, #3f6965);
  margin-bottom: 10px;
}
.salesBlock h3 {
  font-size: 19px;
  padding: 5px 0 0 5px;
  color: #fff;
  text-shadow: 0px 0px 0px black, 0 0 1px #fff;
}
.salesBlock h4 {
  font-size: 14px;
  padding: 0px 0 0 7px;
  color: #fff;
  text-shadow: 0px 0px 0px black, 0 0 1px #fff;
}
.salesBlock b {
  font-size: 40px;
  margin: 14px 5px;
  display: block;
}
.salesBlock em {
  text-decoration: underline;
  font-style: italic;
}
.progressLine {
  height: 5px;
  background: #131f1e;
  width: 100%;
}
.salesBlock p {
  font-size: 12px;
  text-transform: lowercase;
  padding-top: 3px;
  text-shadow: 0px 0px 0px black, 0 0 1px #fff;
}
.salesBlock2 {
  background: #c2263f;
  background: linear-gradient(#c2263f, #57121d);
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tableStage {
  width: 100%;
}
.tableStage span {
  background-color: #cfecfe;
  display: inline-block;
  border-radius: 4px;
  padding: 1px 10px;
  font-size: 11px;
  text-align: center;
  float: right;
}
.tableStage tr:nth-child(2n) span {
  background-color: #e8fdeb;
}
.tableStage tr:nth-child(3n) span {
  background-color: #f1effd;
}
.tableStage tr:nth-child(4n) span {
  background-color: #f9e7eb;
}
.tableStage tr:nth-child(5n) span {
  background-color: #fff8ec;
}

.react-datepicker__input-container input:disabled {
  opacity: 0.5;
}
.apexcharts-toolbar {
  z-index: 0;
  display: none !important;
}
.periodGroup {
  display: table;
  margin: 0 auto;
}
.periodGroup a {
  font-size: 12px;
  font-weight: bold;
  color: #2b2b2b;
  padding: 9px;
  text-decoration: underline;
  text-transform: uppercase;
}
.periodGroup a.active {
  color: #669295;
  text-decoration: none;
}
.mail-left {
  width: 25%;
  float: left;
}
.mail-right {
  width: 75%;
  float: left;
}
.mailButton {
  box-sizing: border-box;
  width: 90%;
  text-align: center;
  margin-top: 20px;
  border-radius: 30px;
  display: block;
}
.mailTable tr {
  box-shadow: none;
}
.mailTable td {
  border-bottom: 1px solid #ababab;
}
.filterBlock .multiSelect {
  width: 66.6%;
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #669295 !important;
  min-height: 42px !important;
  padding-right: 25px;
  padding-top: 8px;
}
.filterBlock .multiSelect .itemContainerItem a {
  display: none;
}
.arrow-down,
.arrow-up {
  text-decoration: none !important;
}
.arrow-down i,
.arrow-up i {
  display: inline-block;
  color: #2b2b2b;
  font-size: 15px;
}
.arrow-down i {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.smsBlock em {
  font-size: 13px;
  display: block;
  font-style: italic;
  padding: 2px 0;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  background: #fff;
  width: 35px;
  height: 35px;
  border-radius: 0;
  overflow: hidden;
  outline: none;
  cursor: inherit;
  display: inline-block;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
input[type="color"]::-moz-focus-inner {
  border: none;
  padding: 0;
}
input[type="color"]::-moz-color-swatch {
  border: none;
  height: 35px;
}

.widthColor {
  float: left !important;
  height: 35px !important;
  border: none !important;
  border-bottom: 1px solid #b2b2b2 !important;
  min-width: 100px !important;
  font-family: "Montserrat-Light" !important;
  color: #67757c !important;
  background: #fff !important;
}
div.widthColor {
  border: none !important;
}
.arrowBlock {
  display: inline-block;
  float: left;
  width: 40px;
  position: relative;
  top: 8px;
}
.arrowBlock i {
  font-size: 18px;
}
.arrowBlock i:hover {
  color: #669295;
}
.kanbanBlock {
  /* width: max-content !important; */
  overflow-x: auto;
  padding: 20px 0;
  min-height: calc(100vh - 50px);
  /* cursor: all-scroll; */
  height: calc(100vh - 50px);
}
.kanban-item {
  background: #fff;
  margin-bottom: 8px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  /*box-shadow: 0px 0px 2px rgba(0,0,0,0.5);*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.kanban-item:first-child {
  margin-top: 8px;
}
.kanban-item:hover {
  cursor: move;
}
.kanban-board header {
  margin-bottom: 8px;
}
.lead_item {
  border-left: 7px solid red;
  min-height: 95px;
}
.kanban-item {
  position: relative;
}
.lead_item b a {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  padding: 5px 0 2px 5px;
  display: block;
  line-height: 19px;
  max-width: 265px;
}
.lead_item em {
  font-size: 12px;
  display: block;
  color: #939393;
  padding-left: 5px;
}
.lead_item i {
  color: #669295;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.right-task a {
  display: block;
  margin-bottom: 0px;
  margin-bottom: -3px;
}
.bottom-task {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}
.it-task {
  display: inline-block;
  font-size: 13px;
  color: #669295;
  padding: 0 0 4px 15px;
  float: left;
  text-decoration: underline;
}
.it-task div {
  /*display: inline-block;
    background: #f54819;
    width: 18px;
    height: 18px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 11px;
    line-height: 18px;
    font-weight: bold;
    margin-left: 2px;
    position: absolute;*/

  display: inline-block;
  color: red;
  font-weight: bold;
  margin-left: 3px;
  font-size: 12px;
}
.it-add-task {
  font-size: 13px;
  color: #669295;
  display: inline-block;
  float: right;
  padding: 0 6px 4px 0;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}
.right-task {
  position: absolute;
  right: 4px;
  top: 3px;
}
.kanban-board-header {
  font-size: 15px;
  padding: 7px;
}
.kanban-title-board {
  font-weight: bold;
}
.kanbanCol {
  min-height: calc(100vh - 50px) !important;
}
.dragList {
  /*max-width: 800px;*/
}
.dragListDelete {
  background: #c2263f;
  height: 35px;
  width: 35px;
  /*display: none;*/
  /*position: absolute;*/
  right: 0px;
  float: left;
}
.dragList li {
  position: relative;
  display: inline-table;
  padding-right: 0px;
}
.dragList li:hover .dragListDelete {
  display: inline-block;
}
.dragListDelete i {
  color: #fff;
  font-size: 17px;
  text-align: center;
  display: block;
  line-height: 35px;
}
.rem:disabled {
  opacity: 0.5;
}
.butt-group a.active {
  opacity: 0.5;
}
.dragList input[type="color"] {
  float: left;
}

.example {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.example__scroll {
  display: flex;
  flex-wrap: wrap;
}

.example__scroll--vertical {
  overflow-y: scroll;
}

.example__scroll--horizontal {
  flex-direction: column;
  overflow-x: scroll;
}

.example__scroll::-webkit-scrollbar {
  display: none;
}

.toogle-list li:hover ul {
  display: block;
}
.toogleListChild {
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 6px;
  background: #fff;
  min-width: 175px;
  right: -6px;
  bottom: 30px;
  display: none;
  z-index: 2;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.tagMail {
  display: inline-block !important;
  background: #669295;
  padding: 3px 7px;
  margin: 1px;
  color: #fff;
}
.goaslParams table {
  width: 100%;
}
.goaslParams input {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid #bdbdbd;
  height: 35px;
  padding: 3px 10px;
  box-sizing: border-box;
  width: 150px;
}
.checkbox,
.radio {
  display: none;
}

.checkbox-custom,
.radio-custom,
.label {
  display: inline-block;
  vertical-align: middle;
}
.checkbox:checked + .checkbox-custom::before,
.radio:checked + .radio-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #413548;
  border-radius: 2px;
}
.radio-custom,
.radio:checked + .radio-custom::before {
  //border-radius: 50%;
  cursor: pointer;
}
.checkedRole {
  display: flex;
  flex-direction: column;
}
.checkedRole input:disabled {
}

.endBlock {
  width: 100%;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 16px;
}
.endBlock h2 {
  font-family: "Montserrat-Black";
  font-size: 22px;
}
.endBlock p {
  font-size: 14px;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
  min-height: 100px;
}
.rbc-event {
  padding: 4px 5px !important;
  border-radius: 1px !important;
  margin: 1px 4px;
  border: 1px solid #ebedf2;
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
}
.rbc-event-content {
  font-size: 12px;
}
.rbc-header {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.rbc-header span {
  color: #898b96;
  font-size: 13px;
}
.rbc-month-view,
.rbc-month-row + .rbc-month-row,
.rbc-day-bg + .rbc-day-bg,
.rbc-header {
  border-color: #ebedf2 !important;
}
.rbc-btn-group button {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  background: #f4f5f8 !important;
  border: 0 !important;
  padding: 12px 12px !important;
  font-size: 14px !important;
}
.calendar {
  background: #fff;
  padding: 20px;
  -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background: #5d988a !important;
  color: #fff !important;
  font-size: 14px;
}
.rbc-label {
  font-size: 12px !important;
  color: #575962 !important;
}
.rbc-toolbar .rbc-toolbar-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #898b96 !important;
}
.rbc-agenda-content tr {
  background: none !important;
}
.rbc-today {
  background-color: #f7f7f7;
}
.rbc-btn-group button {
  border-left: 1px solid #dddddd !important;
  border-right: 1px solid #dddddd !important;
}
.rbc-btn-group button:first-child {
  border-left: none !important;
}
.rbc-btn-group button:last-child {
  border-right: none !important;
}
.rbc-day-slot .rbc-event {
  border: none !important;
}
.tagsName {
  position: relative;
}
.tagsName a:after {
  bottom: -20px;
  left: 0;
  font-size: 14px;
  max-width: 300px;
}
.tagsName__dropdown {
  position: absolute;
  background: #ffffff;
  width: 300px;
  top: 40px;
  z-index: 10;
  padding: 10px;
  border: 1px solid #dadada;
  padding-bottom: 7px;
  box-shadow: 1px 0px 20px rgb(0 0 0 / 20%);
}
.chatListContainer {
  /*padding: 0 20px;*/
}
.chatItem {
  display: table;
  border-bottom: 0.07rem solid #ebedf2;
  width: 100%;
  padding: 8px 15px;
  position: relative;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-out 0.1s;
  -moz-transition: all 0.2s ease-out 0.1s;
  -o-transition: all 0.2s ease-out 0.1s;
  transition: all 0.2s ease-out 0.1s;
}
.chatItem .chatFace {
  display: table-cell;
  vertical-align: middle;
  width: 65px;
}
.chatItem .chatFace img {
  width: 3rem;
  border-radius: 50%;
}
.chatItem .chatName h4 {
  font-family: "Montserrat-Medium";
  color: #2b2b2b;
  padding-top: 6px;
  font-weight: bold;
  font-size: 15px;
}
.chatItem .chatName b {
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
  display: block;
  padding-top: 3px;
}
.chatItem:hover {
  background: #f7f7f7;
  border-width: 10px !important;
}
.chatItem.active {
  background: #f7f7f7;
}
.chatItem.active:after {
  content: "";
  background: rgba(98, 160, 144, 1);
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 48%;
}
.myMessage {
  background: #669295;
  padding: 12px 20px;
  border-radius: 10px;
  color: #fff;
  /*max-width: 80%;*/
  display: inline-block;
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  position: relative;
  min-width: 150px;
  max-width: 350px;
}
.myMessage:after {
  content: "";
  border: 10px solid transparent;
  border-left: 10px solid #669295;
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  right: -17px;
  top: 11px;
}
.myMessage p,
.msgBlock p {
  font-size: 14px;
}
.myMessage p em,
.msgBlock p em {
  display: block;
  font-size: 11px;
  text-align: left;
  padding-top: 1px;
}
.cloud__update-button {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  text-align: center;
  padding: 10px 0 !important;
  color: #fff !important;
  cursor: pointer !important;
  font-family: "Montserrat-Light" !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
.msgBlock p em {
  color: #8c8c8c;
}
.myMessage p em {
  text-align: right;
}
.itemClear:before {
  content: "";
  clear: both;
  display: block;
}
.messageFrom {
  margin-top: 5px;
}
.msgBlock {
  background: #f4f5f8;
  padding: 12px 20px;
  border-radius: 10px;
  display: inline-block;
  float: left;
  position: relative;
  color: #2b2b2b;
  margin-left: 15px;
  min-width: 150px;
  max-width: 350px;
}
.messageFrom .msgImg {
  vertical-align: middle;
  margin-left: 10px;
  float: left;
}
.messageFrom .msgImg img {
  width: 3rem;
  border-radius: 50%;
}
.msgBlock a {
  font-size: 12px;
  color: #669295;
  padding-bottom: 1px;
  text-decoration: underline;
}

.msgBlock:after {
  content: "";
  border: 10px solid transparent;
  border-right: 10px solid #f4f5f8;
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: -17px;
  top: 11px;
}
.chatBlock {
  position: relative;
  height: calc(100vh - 145px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-x: hidden;
}
.sendMessageBlock {
  height: 80px;
  /*position: fixed;*/
  width: 100%;
}
.sendMessageBlock label:after {
  width: 180px;
  right: 30px;
  top: 0;
}
.sendMessageBlock textarea {
  height: 80px;
  padding-right: 60px;
}
.sendMessageBlock {
  position: relative;
}
.sendMessageBlock button {
  position: absolute;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  border: none;
  color: #fff;
  font-size: 22px;
  right: 0px;
  height: 100%;
}
.modal-ok .unlink {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.parrentContactField {
  position: relative;
}
.parrentContactField button {
  background: none;
  border: 0px;
  color: #f4516c;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  padding: 0px !important;
  margin: -5px 8px !important;
  text-decoration: underline;
  text-transform: lowercase;
  display: block;
  float: left;
}
.inputParrent .flaticon-delete-1 {
  color: #f4516c;
  font-size: 15px;
}
.delInpBut {
  width: 25px !important;
}
.fieldSettings li.bb {
  display: inline-block;
  background: #fff;
  margin: 0 2px 5px 0;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.fieldSettings li.active {
  background: #669295;
}
.fieldSettings li.active a {
  color: #fff;
}
.fieldSettings li a.db {
  float: right;
  color: #c2263f;
  font-size: 15px;
}
.fieldSettingsSt .checkbox-custom,
.radio-custom {
  position: relative;
  width: 15px !important;
  height: 15px !important;
  border: 2px solid #ccc !important;
  border-radius: 3px !important;
  cursor: pointer;
}
.fieldSettingsSt .checkbox-custom:before {
  background: #669295 !important;
  top: 2px !important;
  right: 2px !important;
  bottom: 2px !important;
  left: 2px !important;
}
.fieldSettingsSt em {
  display: inline-block !important;
  margin-left: 5px !important;
  position: relative;
  top: 2px !important;
  font-size: 13px;
  text-transform: lowercase;
}
.btns.btn-inside {
  height: 40px;
  float: left;
  line-height: 24px;
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  border-radius: 4px;
}
.about-icon {
  float: right !important;
  margin-right: 0;
  margin-left: 5px;
  font-size: 23px;
  box-sizing: border-box;
}
.about-modal-icon {
  font-size: 16px;
  position: relative;
  top: -7px;
  left: -17px;
}
.checkedRole label {
  padding-top: 2px !important;
  font-size: 12px;
}
.checkedRoleAllowed .radio-custom {
  border: 1px solid #669295 !important;
}
.checkedRoleAllowed .checkbox:checked + .checkbox-custom::before,
.checkedRoleAllowed .radio:checked + .radio-custom::before {
  background: #669295;
}
.checkedRoleOwned .radio-custom {
  border: 1px solid #ffc164 !important;
}
.checkedRoleOwned .checkbox:checked + .checkbox-custom::before,
.checkedRoleOwned .radio:checked + .radio-custom::before {
  background: #ffc164;
}
.checkedRoleDenied .radio-custom {
  border: 1px solid #c2263f !important;
}
.checkedRoleDenied .checkbox:checked + .checkbox-custom::before,
.checkedRoleDenied .radio:checked + .radio-custom::before {
  background: #c2263f;
}
.checkedRole .checkbox-custom,
.radio-custom {
  width: 12px !important;
  height: 12px !important;
  border-radius: 3px !important;
  position: relative;
}
.mod-cont .checkbox-custom:before {
  background: #669295 !important;
}
.multi-select .selected-options .selected-options-badges-list {
  padding: 2px 0 !important;
  min-height: 29px;
}
.multi-select {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  /*padding: 8px 10px;*/
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  min-height: 35px;
  border-radius: 0px !important;
}
.selected-options {
  padding: 0px 0px 2px 5px !important;
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  outline: none !important;
  border: none !important;
  min-height: 35px !important;
}
.multi-select .options {
  height: inherit !important;
  border-top: 1px solid #ccc !important;
}
.options li .option-list {
  padding: 5px !important;
  font-size: 14px;
  text-transform: lowercase !important;
  cursor: pointer !important;
}
.margin-top-right {
  margin: 4px 2px 0 0 !important;
  padding: 2px 7px !important;
  font-size: 13px !important;
  text-transform: lowercase !important;
}
.multirequired .multi-select {
  border: 1px solid red;
}
.options li .option-list:hover {
  background: #669295 !important;
  color: #fff !important;
}
.subtask {
  background: rgba(102, 146, 149, 0.8);
  padding: 5px;
}
.subtask li {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  clear: both;
}
.subtask i {
  font-size: 20px;
  color: #fff;
}
.subtask em {
  padding-left: 3px;
  color: #fff;
}
.subtask tr {
  cursor: pointer;
}
.subtask td {
  padding: 4px 2px;
}
.subTskSquire {
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  background: #fff;
}
.subtask input {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #bbb !important;
  height: 35px !important;
  font-size: 13px !important;
  padding: 0px !important;
}
.subtask input::-webkit-input-placeholder {
  text-align: left !important;
  font-size: 12px !important;
  color: #2b2b2b !important;
}
.subtask input::-moz-input-placeholder {
  text-align: left !important;
}
.subtask input:-ms-input-placeholder {
  text-align: left !important;
}
.subtask input:-moz-input-placeholder {
  text-align: left !important;
}

.subtaskInp {
  padding: 0 5px;
  position: relative;
}
.subtaskInp i {
  position: absolute;
  left: 5px;
  top: 7px;
  cursor: pointer;
  display: none;
}
.subtask .btni {
  font-size: 18px !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 23px !important;
  margin-top: 1px !important;
}
.deleteSubtask {
  //position: absolute;
}
.qualificationBlock {
  border: 2px solid #669295;
  padding: 10px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-top: 10px;
}
.qualificationBlock i {
  font-size: 27px;
  margin-right: 10px;
}
.qualificationBlock span {
  font-size: 15px;
  position: relative;
  top: -3px;
}
.qualificationBlock:hover {
  background: #669295;
  color: #fff !important;
}
.profileImg {
  display: block;
  margin: 0 auto;
  padding: 5px 0;
  height: 120px;
}
.previewComponent {
  padding: 10px 0;
}
.filterBlock .multi-select {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #669295 !important;
  min-height: 40px !important;
  padding-right: 25px;
  padding-top: 0px;
}
.ownersMap li {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 8px 10px;
  background: #a2c7bd;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #fff;
  outline: none !important;
  height: 35px;
  margin-top: 5px;
  position: relative;
}
.ownersMap li a {
  position: absolute;
  right: 10px;
  color: red;
}
.taskteam input {
  background: none !important;
  border: none !important;
  border-bottom: 1px solid #bbb !important;
  height: 35px !important;
  font-size: 13px !important;
  padding-right: 25px !important;
  padding-left: 2px !important;
}
.spImg {
  margin: -4px 0px;
}
.toggleMenu {
  position: relative;
}
.toggleMenu ul {
  position: absolute;
  background: #fff;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  padding: 5px 10px;
  border: 1px solid #bbb;
  display: none;
  z-index: 2;
  min-width: 120px;
}
.toggleMenu ul li {
  padding: 1px 0;
}
.toggleMenu ul li a {
  font-size: 13px;
}
.toggleMenu:hover ul {
  display: block;
}
.newDashboard .analiticsIcon i {
  position: inherit;
  font-size: 50px;
  margin-bottom: 7px;
  display: block;
  color: #5b5b5b;
  text-shadow: 2px 2px 6px #5b5b5b;
}
.newDashboard span {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}
.newDashboard h4 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  color: #669295;
  margin-top: 4px;
  text-shadow: 0px 0px 3px #669295;
  min-height: 22px;
}
.newDashboard h6 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #ffc164;
  font-weight: 500;
  margin-top: 3px;
}
.newDashboard .col6 {
  width: 48%;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.2);
  margin: 1%;
  overflow: hidden;
}
.plusbackground {
  padding: 10px 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.2);
  margin: 0.5%;
  width: 32%;
  font-size: 13px;
}
.plusbackground8 {
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.2);
  margin: 0.5%;
  width: 62%;
  float: left;
}
.plusbackground4 {
  padding: 10px;
  background: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.2);
  margin: 0.5%;
  width: 32%;
  float: left;
}
.goals .apexcharts-legend-text {
  font-size: 12px !important;
}
.goals .apexcharts-legend-marker {
  height: 8px !important;
  width: 8px !important;
}
.goals .apexcharts-legend {
  position: absolute;
  left: 0px !important;
  top: 58px !important;
}
.newDashboard h2 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #6f6f6f;
  text-transform: uppercase;
  margin-top: 5px;
  /*margin: -6px 0 -15px 5px;*/
  font-weight: 900;
}
.custom-date-range-picker * {
  font-family: "Montserrat", sans-serif !important;
}
.newDashboard .color-table {
  margin-top: 10px;
}
.prbookrel {
  position: relative;
}
.prbookabs {
  position: absolute;
  background: #fff;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  z-index: 9;
}
.prbookabs a {
  display: block;
  font-size: 13px;
  color: #2b2b2b;
  text-decoration: none;
  padding: 5px;
}
.prbookabs em {
  font-size: 13px;
  font-style: italic;
}
.prbookabs a:hover {
  background: #f6f6f6;
}
.prbookcls,
.prbookcls:hover {
  background: #669295 !important;
  color: #fff !important;
  font-size: 12px !important;
  text-align: center !important;
  padding: 1px !important;
  text-transform: lowercase;
}
.ReactApexChart * {
  font-size: 12px !important;
}
.leaderTable {
  width: 100%;
  margin-top: 30px;
}
.leaderTable td:last-child {
  text-align: right;
}
.leaderTable tr:nth-child(2n + 1) {
  background: #f5f5f5;
}
.infinite-scroll-component {
  overflow: hidden !important;
}
.editBlc {
  float: inherit;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 10px;
}
.editBlc h1,
.feed__post h1,
.editBlc h2,
.editBlc h3,
.editBlc h4,
.editBlc h5,
.editBlc h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.editBlc h1 {
  font-size: 30px;
  padding: 15px 0;
}
.editBlc .ql-size-huge,
.editBlc h2 {
  font-size: 25px;
  padding-top: 10px;
}
.editBlc h3 {
  font-size: 22px;
  padding-top: 10px;
}
.editBlc .ql-size-large,
.editBlc h4 {
  font-size: 20px;
  padding-top: 10px;
}
.editBlc .ql-size-large,
.editBlc h5 {
  font-size: 18px;
  padding-top: 10px;
}
.editBlc p {
  padding: 5px 0;
  font-size: 14px;
  color: #4c4c4c;
}
.editBlc strong,
.editBlc b {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.editBlc em {
  font-style: italic;
}
.editBlc iframe {
  margin: 0 auto;
  display: block;
  padding: 10px 0;
}
.editBlc ol {
  display: block;
  list-style-type: decimal;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.editBlc ul {
  display: block;
  list-style-type: disc;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.editBlc li {
  display: list-item;
  text-align: -webkit-match-parent;
  line-height: 22px;
}
.editBlc table {
  border-collapse: collapse;
  width: 100%;
  display: table;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
}
.editBlc tr {
}
.editBlc td {
  border: 1px solid #ccc;
  padding: 10px;
}
.bgcall {
  background: rgba(000, 000, 000, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 51;
}
.bgcall > form {
  z-index: 9999;
}
.callItem {
  display: block;
  border: 2px solid #669295;
  padding: 10px;
  margin-top: 10px;
}
.callItem span {
  font-size: 14px;
  display: block;
  margin-top: 2px;
  text-align: center;
}
.callItem h3 {
  font-size: 18px;
  font-weight: bold;
  color: #669295;
  font-family: "Montserrat-Black";
  text-align: center;
}
.callItem a {
  background: #fff;
  border: 0px;
  display: block;
  color: #669295;
  text-decoration: underline;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 1px;
  padding: 0;
  font-size: 14px;
  text-align: center;
}
.m-green {
  display: block !important;
  text-align: center;
}
.m-green:hover {
  background: #f5f5f5;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.2);
}
.rotateIn {
  -moz-transform: rotate(45deg); /* Для Firefox */
  -ms-transform: rotate(45deg); /* Для IE */
  -webkit-transform: rotate(45deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(45deg); /* Для Opera */
  transform: rotate(45deg);
  color: #669295 !important;
  width: 15px !important;
}
.rotateOut {
  -moz-transform: rotate(225deg); /* Для Firefox */
  -ms-transform: rotate(225deg); /* Для IE */
  -webkit-transform: rotate(225deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(225deg); /* Для Opera */
  transform: rotate(225deg);
  color: #ffc164 !important;
  width: 15px !important;
}
.ncl {
  border: none !important;
  border-bottom: 1px solid #b2b2b2 !important;
  min-width: 100px !important;
  font-family: "Montserrat-Light" !important;
  color: #67757c !important;
  background: #fff !important;
  display: block;
  padding: 13px 0 7px 10px;
  max-width: 500px;
}
.ncl b {
  font-size: 14px;
}
.ncl i {
  cursor: pointer;
  float: right;
  color: #c2263f;
  margin-top: 5px;
}
.dropzone {
  border: 1px dashed #bbb !important;
  background: #f7f7f7;
}
.dropzone .dz-message {
  margin: 3.5em 0 !important;
  color: rgb(59, 65, 70) !important;
  font-size: 14px !important;
}
.task-dropzone .dropzone {
  min-height: 100px !important;
}
.mailDropzone .filepicker,
.mailDropzone .dz-details {
  padding: 0px;
}
.mailDropzone .dz-preview {
  margin: 5px !important;
}
.mailDropzone .dz-image {
  border-radius: 0px !important;
  width: 105px !important;
  height: 80px !important;
}
.mailDropzone .dz-size {
  display: none;
}
.mailDropzone .dz-error-message {
  top: 100px !important;
}
.funnelLeftBar {
  width: 20%;
  float: left;
}
.funnelLeftBar h2 {
  font-family: "Montserrat-Black";
  color: #707070;
  padding-bottom: 3px;
  text-align: center;
}
.fLiftIt {
  background: #fff;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  /* padding: 10px;*/
  margin-bottom: 5px;
  border: 1px solid #dfdfdf;
}
.bpItemCl {
  padding: 10px;
  height: 60px;
  overflow: hidden;
  border-radius: 8px;
}
.fLiftIt i,
.fLiftIt img {
  font-size: 35px;
  float: left;
  color: #669295;
  padding-right: 10px;
}
.fLiftIt a {
  display: block;
  text-decoration: underline;
}
.fLiftIt em {
  color: #767676;
  font-size: 14px;
  font-weight: bold;
}
.okBtn {
  border: 1px solid #dedede;
  display: inline-block !important;
  text-align: center;
  padding: 12px 14px;
  font-size: 13px;
  font-weight: 300 !important;
  color: #5f5f5f;
  width: 100%;
  box-sizing: border-box;
}
.okBtn:hover {
  background: #f7f7f7;
  text-decoration: none;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}
.funnelRightBar {
  float: left;
  background: #fff;
  width: 79%;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}
.fullContain {
  width: max-content;
  min-height: 300px;
  min-height: calc(100vh - 150px);
  cursor: grab;
}
.funellCategory {
  padding: 0 5px;
  width: 300px;
  display: inline-block;
  float: left;
}
.funnelRightBar h2 {
  text-align: center;
  border-bottom: 4px solid #ff6e08;
  padding-bottom: 5px;
  color: #747474;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.minIcon i {
  font-size: 25px;
}
.minIcon {
  display: flex;
}
.minIcon a {
  font-size: 13px;
  text-decoration: none;
  font-weight: bold;
}
.minIcon em {
  font-size: 13px;
  display: block;
  line-height: 15px;
  padding-top: 3px;
}
.thTags {
  font-size: 13px;
  display: block;
  line-height: 15px;
  margin-bottom: 5px;
}
.mailModalItem {
  border: 2px solid #669295;
  margin-bottom: 10px;
  padding: 10px;
  width: 95%;
  box-sizing: border-box;
}
.topModalBlock {
  background: #fff;
  /* height: calc(100vh - 100px); */
  width: 60%;
  position: fixed;
  z-index: 99;
  top: -5000px;
  left: 20%;
  min-height: 330px;
}
.topModalBlock.c-open {
  top: 70px;
}
.itemSourceCont {
  width: 24%;
  border: 2px solid #669295;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  background: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: inline-block;
  margin: 0.5%;
}
.itemSourceCont i {
  font-size: 40px;
  color: #669295;
}
.itemSourceCont h2 {
  font-family: Montserrat-Light;
  color: #595959;
  font-size: 14px;
  text-transform: lowercase;
  padding: 1px 0;
  font-weight: bold;
}
.itemSourceCont a {
  background: linear-gradient(
    to right,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(48, 48, 48) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62a090', endColorstr='#1b5c4c', GradientType=1 );
  color: #fff;
  font-weight: bold;
  padding: 7px 17px;
  font-size: 12px;
  text-transform: uppercase;
  margin: 5px 0;
  display: block;
}
.itemSourceCont a:hover {
  background: linear-gradient(
    to right,
    rgba(48, 48, 48) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(98, 160, 144, 1) 100%
  );
}
.miniBuilder .fLiftIt {
  width: 250px;
  min-height: 60px;
  position: relative;
  border-radius: 10px;
}
.toRightBp {
  margin-left: 15px;
  float: left;
}
.toRightBp:before {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  content: "";
  border-left-color: #a7a7a7;
  border-right: 0;
  display: inline-block;
  position: absolute;
  left: -12px;
  top: 30px;
}
.condition .toRightBp:nth-child(2) {
  margin-left: 25px;
}
.condition .toRightBp:nth-child(2):before {
  border-left-color: #669295;
}
.condition .toRightBp:nth-child(2):after {
  content: "true" !important;
  font-size: 12px;
  color: #669295;
  border: none;
  top: 33px;
  left: -36px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
}
.condition .wrapBpBlock {
  position: relative;
  margin-top: 25px;
  z-index: 1;
  padding: 10px;
}
.condition .wrapBpBlock:before,
.fullContainBtn:before {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid transparent;
  display: inline-block;
  margin: 30px 10px;
  border-top-color: #c2263f;
  border-bottom: 0;
  position: absolute;
  top: -44px;
  left: 67px;
}
.condition .wrapBpBlock:after,
.fullContainBtn:after {
  content: "false" !important;
  font-size: 12px;
  color: #c2263f;
  border: none;
  top: -29px;
  left: 69px;
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Montserrat-Light !important;
}
.addActivityBp {
  height: 79px;
  font-family: Montserrat-Black;
  font-size: 23px;
  display: inline-block;
  background: #f1f1f1;
  width: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 26px;
  line-height: 82px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}
.node + .addActivityBp:before {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  content: "";
  border-left-color: #a7a7a7;
  border-right: 0;
  display: inline-block;
  position: absolute;
  left: -12px;
  top: 30px;
}
.node + .addActivityBp {
  margin-left: 15px;
}
.conditionBlock + .addActivityBp:after {
  content: "true" !important;
  font-size: 12px;
  color: #669295;
  border: none;
  top: -1px;
  left: -36px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Montserrat-Light", sans-serif !important;
}
.conditionBlock + .addActivityBp:before {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  content: "";
  border-left-color: #669295;
  border-right: 0;
  display: inline-block;
  position: absolute;
  left: -12px;
  top: 30px;
  background: none !important;
}
.conditionBlock + .addActivityBp {
  margin-left: 25px;
}
.condition .toRightBp {
  margin-top: 25px;
}
.condition:first-child .toRightBp,
.condition:first-child .toRightBp {
  margin-top: 0;
}
.condition:first-child .wrapBpBlock {
  margin-top: 0px;
}
.condition:first-child .wrapBpBlock:first-child:before,
.condition:first-child .wrapBpBlock:first-child:before {
  border: none;
}
.conditionBlock {
  background: rgba(98, 160, 145, 0.1);
  width: 150px !important;
}
.entityBlock {
  width: 150px !important;
  padding: 10px;
}
.conditionBlock a,
.entityBlock a {
  display: none;
}
.conditionBlock em,
.entityBlock em {
  text-align: center;
  display: block;
  font-size: 13px;
}
.entity + .condition > .conditionBlock:after {
  content: "start" !important;
  color: #669295;
}
.entity + .condition > .conditionBlock:before {
  border-top-color: #669295;
}
.ccs12 * {
  font-size: 12px !important;
}
.fullContainBtn {
  border: 1px solid #dedede;
  display: inline-block !important;
  text-align: center;
  padding: 12px 14px;
  font-size: 11px;
  box-sizing: border-box;
  width: 173px;
  background: #669295 !important;
  color: #ffff;
  font-weight: 900 !important;
  text-transform: uppercase;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: Montserrat-Black;
  position: relative;
  margin-top: 24px;
}
.bpmModal .right-modal {
  width: 800px;
  right: -800px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.filterBpm td {
  padding: 5px;
}
.sbform input:disabled {
  opacity: 0.7;
}
.filterBpm .errorMsg {
  display: none !important;
}
.formulaTxt .rc6 {
  padding: 0;
  margin-bottom: 5px;
}
.arrowBlockFields {
  float: right;
  top: 0px;
  right: 3px;
  background: #fff;
  display: none;
}
.bb:hover .arrowBlockFields {
  display: inline-block;
}
.startChat {
  background: #669295;
  border: none;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 50px;
}
.tariffItem {
  width: 32%;
  text-align: center;
  border: 3px solid #979797;
  padding: 13px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
}
.tariffItem h2 {
  font-size: 20px;
}
.tariffItem em {
  display: block;
  font-size: 13px;
}
.tariffItem b {
  font-family: "Montserrat-Black";
  font-size: 26px;
  color: #5d5d5d;
  line-height: 28px;
}
.tariffItem b:before,
.tariffItem b:after {
  content: "";
  border-top: 2px solid #979797;
  width: 40px;
  height: 1px;
  display: block;
  margin: 7px auto;
}
.tariffItem li {
  font-size: 13px;
  font-style: italic;
  color: #6e6e6e;
}
.choosePlan {
  border: 3px solid #669295;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.choosePlan b {
  color: #669295;
}
.choosePlan b:before,
.choosePlan b:after {
  border-top: 2px solid #669295;
}
.choosePlan h2 {
  color: #669295;
  font-weight: bold;
}
.tariffform td {
  padding: 0 5px;
}
.react-calendar-timeline .rct-header-root {
  background: #669295 !important;
  border-bottom: 0 !important;
}
.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}
.rct-item {
  background: #669295 !important;
  border-color: #5f8d82 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 2 !important;
}
.rct-dateHeader {
  font-size: 12px !important;
  color: #2b2b2b;
}
.rct-sidebar {
  font-size: 12px !important;
  color: #2b2b2b;
}
.rct-item-content {
  font-size: 11px !important;
}
.react-calendar-timeline {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  z-index: 1 !important;
  border-color: #dbdbdb !important;
}
.react-calendar-timeline .rct-dateHeader {
  border-left: 1px solid #dbdbdb !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  z-index: 2 !important;
}
.appsItem {
  border: 2px solid #669295;
}
.sourceItem {
  border: 2px solid #669295;
  padding: 15px;
  text-align: center;
  width: 24%;
  margin: 0.5%;
  display: inline-block;
  box-sizing: border-box;
  height: 145px;
}
.sourceItem i {
  display: block;
  font-size: 50px;
  text-align: center;
  color: #2b2b2b;
}
.sourceItem span {
  text-align: center;
  font-weight: 900;
  display: block;
  font-family: "Montserrat-Black", sans-serif;
  font-size: 13px;
  padding-top: 4px;
  color: #669295;
}
.sourceItem a {
  background: #669295;
  color: #fff;
  display: block;
  padding: 6px 0;
  margin-top: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}
.left-heading-success {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  text-align: center;
  /*margin-top: 15px;*/
  padding: 10px 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 0 0;
  font-weight: bold;
}
.left-heading-end {
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  margin-top: 20px;
}
.feed-right-body {
  background: #ffffff;
  padding: 20px 15px 5px 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.feed-right-body-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.feed-right-body-item-checkbox {
  height: 24px;
  width: 24px;
  background: #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 15px;
}
.feed-right-body-item-text {
  padding-right: 5px;
  flex-grow: 1;
}
.feed-right-body-item-text span {
  display: block;
  font-size: 15px;
  font-weight: bold;
}
.feed-right-body-item-text em {
  color: #b5b5c3 !important;
  font-size: 13px;
  font-weight: bold;
  display: block;
  padding-top: 2px;
}
.feed-right-body-item-input input {
  border: none;
  border-bottom: 1px solid #b5b5c3;
  margin-bottom: 15px;
  width: 100%;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
  outline: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.feed-right-body-item-input input::placeholder {
  font-size: 13px !important;
}
.feed-right-body-item-actions i {
  color: #d27772;
  display: none;
  cursor: pointer;
  font-size: 16px;
}
.feed-right-body-item:hover .feed-right-body-item-actions i {
  display: block;
}
.fbItem {
  width: 100%;
  background: #f7f7f7;
  padding: 15px 10px;
  box-sizing: border-box;
  margin-top: 6px;
  /*border: 1px solid #e1e1e1;*/
  box-shadow: 0 0 5px rgba(66, 103, 178, 0.2);
  border: 2px solid #4267b2;
}
.fbItem span {
  color: #2b2b2b;
  font-size: 14px;
  font-weight: bold;
}
.fbItem a {
  background: #c2263f;
  color: #fff;
  float: right;
  padding: 7px 15px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: -4px;
}
.connectPageFb {
  background: #4267b2;
  color: #fff;
  padding: 14px 20px;
  margin: 4px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.flowItem {
  background: #fff;
  height: 180px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.flowItemHeader {
  height: 50px;
  background: #efefef;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0cece;
  box-sizing: border-box;
}
.flowItemHeader h3 {
  font-size: 16px;
  margin-top: 15px;
  display: inline-block;
  color: #2b2b2b;
  text-shadow: 2px 2px 3px rgba(000, 000, 000, 0.2), 0 0 0em #2b2b2b;
}
.flowItemIcon {
  background: rgba(76, 135, 138, 1);
  background: -moz-linear-gradient(
    -45deg,
    rgba(76, 135, 138, 1) 0%,
    rgba(98, 141, 144, 1) 50%,
    rgba(55, 91, 94, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0%, rgba(76, 135, 138, 1)),
    color-stop(50%, rgba(98, 141, 144, 1)),
    color-stop(100%, rgba(55, 91, 94, 1))
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(76, 135, 138, 1) 0%,
    rgba(98, 141, 144, 1) 50%,
    rgba(55, 91, 94, 1) 100%
  );
  background: -o-linear-gradient(
    -45deg,
    rgba(76, 135, 138, 1) 0%,
    rgba(98, 141, 144, 1) 50%,
    rgba(55, 91, 94, 1) 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    rgba(76, 135, 138, 1) 0%,
    rgba(98, 141, 144, 1) 50%,
    rgba(55, 91, 94, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(76, 135, 138, 1) 0%,
    rgba(98, 141, 144, 1) 50%,
    rgba(55, 91, 94, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c878a', endColorstr='#375b5e', GradientType=1 );
  width: 50px;
  height: 100%;
  float: left;
  margin-right: 7px;
}
.flowItemIcon i {
  font-size: 28px;
  color: #fff;
  margin: 9px;
  display: block;
  text-shadow: 1px 1px 2px #d3d3d3, 0 0 1em #cbcbcb;
  text-align: center;
}
.bodyLeft {
  width: 50px;
  float: left;
}
.flowBodyRight {
  float: left;
}
.flowBodyRight em {
  font-size: 14px;
  color: #669295;
  text-decoration: underline;
  margin-top: 7px;
  display: block;
  padding-left: 7px;
}
.bodyLeftArrow {
  width: 1px;
  height: 80px;
  border-left: 1px solid #bbb;
  margin: 0 auto;
  margin-top: 8px;
}
.bodyLeft i {
  font-size: 25px;
  color: #bbb;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 3px;
}
.bodyLeft i:hover {
  cursor: pointer;
  color: #669295;
  text-shadow: 1px 1px 2px #2b2b2b, 0 0 1em #2b2b2b;
}
.flowMessageBlock {
  height: 180px;
  display: flex;
  align-items: flex-start;
  overflow: auto;
}
.flowMessageBlock .from {
  //background: #c2263f;
  display: inline-block;
  text-align: left;
  padding: 10px 15px;
  border-radius: 0px 14px 14px 14px;
  color: #fff;
  font-size: 14px;
  margin-top: 0px;
  background: linear-gradient(-45deg, #e66465, #b3152e);
  box-shadow: 0 0 10px rgba(194, 38, 63, 0.5);
}
.from_brown {
  color: #fff !important;
  background: linear-gradient(
    -45deg,
    rgb(153, 153, 153),
    rgb(52, 52, 52)
  ) !important;
  box-shadow: 0 0 10px rgba(000, 000, 000, 0.5) !important;
}
.flowGradient {
  background: linear-gradient(-45deg, #e66465, #b3152e);
}
.flowMessageBlock .to {
  //background: #c2263f;
  display: inline-block;
  text-align: right;
  padding: 10px 15px;
  border-radius: 14px 0px 14px 14px;
  color: #fff;
  font-size: 14px;
  margin-top: 0px;
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  box-shadow: 0 0 10px rgba(96, 175, 179, 0.5);
}
.flowMessageBlock span {
  display: inline-block;
  border: 2px solid #717c7d;
  padding: 7px 11px;
  margin: 8px 2px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #717c7d;
  box-shadow: 0 0 15px rgba(52, 83, 86, 0.3);
}
.flowsHeaderActions {
  width: 90px;
  float: right;
  margin: 8px 0;
}
.flowsHeaderActions a {
  padding: 0 8px;
}
.flowsHeaderActions i {
  color: #2b2b2b;
  font-size: 26px;
  text-shadow: 1px 1px 2px #2b2b2b, 0 0 1em #2b2b2b;
  transition: all 0.3s ease;
  display: inline-block;
}
.flowActDelete i {
  color: #b3152e;
  text-shadow: 1px 1px 2px #c2263f, 0 0 1em #c2263f;
}
.flowsHeaderActions i:hover {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.flowMessageBlock img {
  max-width: 300px;
  max-height: 170px;
}
.flowCardImgBlock {
  height: 115px;
  background-position: 50% 50%;
  background-size: cover;
  display: block;
  border-radius: 10px 10px 0 0;
}
.flowCard {
  width: 60%;
  background: #fff;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 400px;
}
.flowCard h5 {
  font-family: "Montserrat-Bold";
  color: #2b2b2b;
  padding: 4px 10px;
}
.flowCard b {
  color: #787878;
  font-size: 14px;
  padding: 0 10px;
}
.flowCard a {
  border-top: 1px solid #669295;
  text-align: center;
  display: block;
  padding: 10px 0;
  margin-top: 8px;
  font-weight: bold;
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.flowCard a:hover {
}
.flowModalDropdown {
  position: absolute;
  right: 0;
  width: 200px;
  top: 4px;
  z-index: 2;
}
.flowModalDropdown > a {
  display: inline-block;
  text-align: right;
  background: #669295;
  color: #fff;
  float: right;
  padding: 4px 7px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.flowdropDownDiv {
  /*display: block;*/
  background: #fff;
  margin-top: 25px;
  display: none;
}
.flowModalDropdown > a:hover + .flowdropDownDiv {
  display: block;
}
.flowdropDownDiv a {
  display: block;
  text-align: right;
}
.flowInput {
  background-clip: padding-box;
  border: 1px solid #dedede;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  background: #f9f9f9;
  outline: none !important;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flowInput i {
  width: 22px;
  font-size: 21px;
  padding: 5px;
  cursor: pointer;
}
.flowInput b {
  padding: 5px 2px;
}
.flowInput input,
.flowTextarea textarea {
  width: inherit;
  border: none;
  background: none;
  padding: 8px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: #2b2b2b;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.flowToogleBlock {
  position: absolute;
  right: 0;
  background: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px 0;
  z-index: 2;
  display: none;
  max-height: 200px;
}
.flowToogleBlock a {
  display: block;
  color: #2b2b2b;
  padding: 7px 15px;
  font-size: 13px;
}
.flowToogleBlock a:hover {
  background: #669295;
  color: #fff;
}
.flowInput b:hover .flowToogleBlock {
  display: block;
}
.flowTextarea {
  height: inherit !important;
}
.chatbotModal {
  width: 800px;
  right: -800px;
}
.chatBotFilterItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.choiceBot .subTskSquire {
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 5px;
  width: 15px;
  height: 14px;
  position: relative;
  top: 2px;
}
.choiceBot b {
  color: #fff;
  display: inline-block;
  text-shadow: 0px 0px 0px white, 0 0 1em white;
}
.choiceBot em {
  font-size: 12px;
  color: #fff;
  text-shadow: 0px 0px 0px white, 0 0 1em white;
}
.choiceBot > div {
  padding: 9px;
  margin: 5px 0px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.choiceBot i {
  color: #669295;
  font-size: 18px;
  position: relative;
  top: -3px;
  left: -1px;
}
.chatNav {
  background: #f5f5f5;
  position: absolute;
  right: 0;
  top: 0px;
  padding: 10px 15px;
  text-align: right;
  box-shadow: 0 0 10px rgba(27, 92, 76, 0.2);
}
.chatNav li {
  line-height: 24px;
}
.chatNav li a {
  font-size: 14px;
  color: #2b2b2b;
}
.chatNav li a:hover {
  text-decoration: underline;
}
.iconAdd {
  color: #669295;
  font-size: 20px;
  display: inline-block;
  margin-top: 12px;
  cursor: pointer;
}
.edDell {
  font-size: 23px;
  color: rgb(255, 0, 0);
  margin: 6px 3px;
  font-weight: bold;
  display: inline-block;
}
.color100 input[type="color"] {
  width: 100%;
}
.dataTable {
  padding: 0;
  background: #f6f6f6;
  box-shadow: none;
  font-size: 13px;
}
.dataTableLink {
  width: 18%;
  float: left;
}
.dataTableLine {
  width: 82%;
  float: right;
  background: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.2);
  font-size: 13px;
}
.dataTableLink a {
  color: #2b2b2b;
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
  display: block;
  padding: 14px 8px;
  box-sizing: border-box;
}
.dataTableActive {
  background: #669295;
}
.dataTableActive a {
  color: #fff;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dataTableLink a:hover {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  color: #2b2b2b;
}
.dataTableLink li:last-child a {
  border: none;
}
.integration ul {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.integration h1 {
  font-family: "Montserrat", sans-serif;
  color: #525252;
  margin: 2px 0px;
  font-size: 21px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 900;
}
.integration li {
  width: 175px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.integration li img {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.integration li:first-child {
  margin-left: 0;
}
.integration h2 {
  font-family: "Montserrat", sans-serif;
  color: #525252;
  margin: 2px 0px;
  font-size: 15px;
  text-align: center;
  font-weight: 900;
}
.integration p {
  font-size: 12px;
  color: #b9b9b9;
  text-align: center;
  margin: 2px 0;
  font-weight: 500;
}
.integration a {
  border: 1px solid #669295;
  padding: 6px 13px;
  border-radius: 20px;
  font-size: 12px;
  color: #669295;
  margin: 5px auto;
  display: block;
  text-align: center;
  max-width: 100px;
}
.integration a.active {
  background: #669295;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.integration a:hover {
  background: #669295;
  color: #fff;
}
.errorComp h1 {
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase;
  margin-top: 15px;
  color: #1b5c4c;
}
.errorComp p {
  font-size: 15px;
  display: block;
  max-width: 600px;
  text-align: center;
  margin: 10px auto;
}
.errorComp a {
  background: linear-gradient(
    to right,
    rgba(98, 160, 144, 1) 0%,
    rgba(27, 92, 76, 1) 69%,
    rgba(27, 92, 76, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62a090', endColorstr='#1b5c4c', GradientType=1 );
  color: #fff;
  font-weight: bold;
  padding: 15px 0px;
  display: block;
  max-width: 240px;
  text-align: center;
  border-radius: 30px;
  box-shadow: 1px 1px 20px 0 rgba(91, 153, 137, 0.44),
    1px 1px 4px 0 rgba(6, 2, 102, 0.15);
  margin: 11px auto;
  text-transform: uppercase;
  font-size: 12px;
}
.widthColorTable td {
  padding: 0px 5px;
  width: 100%;
}
.plusbutton {
  color: #669295;
  border: none;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
/*.chatra--webkit {
    right: -95px !important;
    bottom: 0 !important;
    !*width: 140px !important;*!
}
.chatra--webkit:hover {
    right: 0 !important;
}
.chatra--expanded {
    right: 0 !important;
}*/

.stageDiv {
  font-size: 13px;
}
.flexDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stageParItem {
  padding: 5px;
  position: relative;
}
.openStage {
  font-size: 23px;
  color: #669295;
  position: absolute;
  left: -11px;
  top: 0px;
  font-weight: bold;
  cursor: pointer;
}
.ql-editor strong {
  font-weight: bold;
}
.ql-editor em {
  font-style: italic;
}
.task-statuses {
  display: flex;
  border: 1px solid #dedede;
}
.task-statuses button {
  flex-grow: 1;
  background: #f9f9f9;
  border: none;
  border-left: 1px solid #dedede;
  font-size: 13px;
  padding: 6px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.task-statuses button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.task_active {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  color: #fff;
}
.task-statuses button:first-child {
  border: none;
}
.task-date {
  display: flex;
  margin-top: 10px;
}
.task-date > div {
  flex-grow: 1;
}
.task-date i {
  font-size: 20px;
  margin-top: 17px;
  color: #8a8a8a;
}
.task-date input {
  background: #fff !important;
  border: none !important;
  height: 20px !important;
  text-decoration: underline;
  cursor: pointer;
}
.task-hr {
  color: #cecece;
}
.react-datepicker-popper {
  z-index: 2;
}
.task-remind .react-datepicker-popper {
  left: -70px !important;
}
.iframe-task-container {
  background-clip: padding-box;
  border: 1px solid #dedede;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  background: #f9f9f9;
  outline: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
}
.modal-tab {
  display: flex;
  justify-content: space-around;
  border: 1px solid #669295;
  border-radius: 6px;
}
.modal-tab a {
  flex-grow: 1;
  text-align: center;
  padding: 5px 0;
  width: 50%;
}
.modal-tab a.active {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.related-read-more {
  background: #ffffff;
  border: none;
  color: #669295;
  width: 100%;
  padding: 8px;
  font-weight: bold;
  text-align: center;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.pagination {
  display: flex;
  align-items: center;
  float: right;
  padding: 5px 10px;
  /*background: #fff;
    border: 2px solid #669295;*/
}
.pagination__arrow-left {
  cursor: pointer;
}

.pagination__container {
  display: flex;
  margin: 0 20px;
}

.pagination__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 5px;
  /*background-color: #f6f6f6;*/
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.pagination__item:last-child {
  margin-right: 0;
}

.pagination__item--active {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.pagination__item-dots {
  background-color: transparent;
  color: #bbb;
}
.pagination__arrow-right {
  transform: rotate(180deg);
  cursor: pointer;
}
.toogle-bottom .btns:after {
  right: 0;
}
.create-activ .AutoSelectParent__child {
  /*display: none !important;*/
}

.parentBox {
  display: flex;
  flex-direction: column;
}
.bot-constructor-block {
  width: fit-content;
}
.childBox {
  display: flex;
}
.parentBox span {
  display: block;
  margin: 0 auto;
  padding: 5px;
}
.parentBox em {
  background: #e6e6e6;
  padding: 12px 20px;
  border-radius: 10px;
  color: #3a3a3a;
  position: relative;
  width: 200px;
  height: 80px;
  display: block;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.botConstructorMessage {
  width: 300px;
  position: relative;
  //padding-right: 40px;
  /*padding-top: 10px;*/
}
.botConstructorChild {
  width: 300px;
  right: 0;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.botConstructorActions {
  position: absolute;
  width: 37px;
  height: 100px;
  top: -4px;
  right: -40px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.botConstructorMessage:hover .botConstructorActions {
  right: 0px;
}
.botConstructorMessage:hover .botConstructorChild {
  margin-left: -40px;
}
.botConstructorActions_button {
  border: none;
  border-radius: 8px 0 8px 8px;
  width: 34px;
  height: 34px;
  color: #ffffff;
  font-size: 20px;
  margin-left: 3px;
  margin-top: 5px;
}
.botConstructorActions_button:after {
  right: 0;
  min-width: 150px;
}
.botConstructorActions_delete {
  background: linear-gradient(-45deg, #e66465, #b3152e);
  box-shadow: 0 0 5px rgba(194, 38, 63, 0.5);
}
.botConstructorActions_add {
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  box-shadow: 0 0 5px rgba(96, 175, 179, 0.5);
}
.botConstructorActions_edit {
  background: linear-gradient(-45deg, rgb(153, 153, 153), rgb(52, 52, 52));
  box-shadow: 0 0 5px rgba(000, 000, 000, 0.5);
}
.botConstructorActions_edit b {
  transform: rotate(185deg);
  display: block;
  margin-left: 2px;
  margin-top: 4px;
  font-size: 21px;
}
.botConstructorActions_add b {
  transform: rotate(45deg);
  display: block;
  margin-left: 3px;
  margin-top: -1px;
}
.botConstructorActions_delete b {
  /*transform: rotate(45deg);*/
  display: block;
  margin-top: -3px;
}
.botConstructorAction {
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(96, 175, 179, 0.5);
  padding: 9px 15px;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
.botConstructorTrigger {
  background: linear-gradient(-45deg, #e66465, #b3152e);
  box-shadow: 0 0 10px rgba(194, 38, 63, 0.5);
  border-radius: 10px;
  padding: 9px 15px;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
.botConstructorDelay {
  background: linear-gradient(-45deg, #999, #343434);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 9px 15px;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
.botConstructorButton {
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  background: linear-gradient(-45deg, #999, #343434);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 7px 16px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 5px;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.botConstructorButton:hover {
  background: linear-gradient(-45deg, #b3152e, #e66465);
}
.botConstructorAction_rich_media {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.botConstructorAction_rich_media img {
  max-height: 150px;
  margin: 0 auto;
  display: block;
  border-radius: 10px 10px 0 0;
}
.botConstructorAction_rich_media h3 {
  font-size: 15px;
  font-weight: bold;
  padding: 3px 3px 0 3px;
}
.botConstructorAction_rich_media p {
  font-size: 13px;
  padding: 3px;
  color: #676767;
  overflow: hidden;
  height: 30px;
}
.botConstructorAction_rich_media a {
  width: 100%;
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  display: block;
  color: #ffffff;
  text-align: center;
  padding: 7px 0;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  margin-top: 5px;
}
.treeWrapperSettings {
  width: 45px;
  float: right;
  margin-right: 2px;
}
.treeWrapperSettings a {
  width: 45px;
  height: 45px;
  background: linear-gradient(-45deg, rgb(96, 175, 179), rgb(49, 76, 79));
  color: #fff;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(96, 175, 179, 0.5);
  margin-bottom: 5px;
}
.treeWrapperSettings a i:after {
  right: 0;
  min-width: 150px;
}
.center-modal {
  background: #fff;
  min-width: 1000px;
  width: 60%;
  position: fixed;
  z-index: 51;
  left: 20%;
  top: 10%;
  height: 80%;
  overflow: auto;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.center-modal-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #bbb;
}

.center-modal h2 {
  font-family: "Montserrat", sans-serif;
  color: #4b4b4b;
  font-size: 20px;
  font-weight: 900;
}
.center-modal .center-modal-header i {
  font-size: 28px;
  color: #2b2b2b;
  cursor: pointer;
}
.center-modal-body {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.center-modal-top {
  flex-grow: 1;
}
.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10px;
}

.products-table {
  width: 100%;
}
.products-table th {
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #ededed;
}
.products-table td {
  padding: 20px 10px;
  border-bottom: 1px solid #ededed;
}
.products-table tr:last-child td {
  border: none;
}
.modal-description p {
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 1px solid #bbb;
  color: #5f5f5f;
}
.products-table-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.custom-checkbox {
  position: relative;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}
.custom-checkbox-active:before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background: #669295;
  border-radius: 2px;
}
.custom-checkbox-relative {
  display: flex;
  align-items: center;
}
.custom-checkbox-label {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #8c8c8c;
  font-weight: 500;
  display: block;
  margin-left: 5px;
  margin-top: -1px;
  cursor: pointer;
}
.empty-table {
  color: #2b2b2b;
  font-size: 15px;
  padding: 8px;
  display: block;
}
.product-categories-parent {
  font-size: 15px;
  color: #669295;
  margin-left: 10px;
  line-height: 24px;
}

.product-categories-delete {
  color: #c2263f;
  display: inline-block;
  font-weight: bold;
  margin-left: 6px;
  font-size: 16px;
  display: none;
}

.product-categories-child {
  list-style: square;
  margin-left: 30px;
}
.product-categories li {
  cursor: pointer;
}
.product-categories li:hover > span {
  font-weight: bold;
  text-decoration: underline;
}
.product-categories li span:hover > a {
  display: inline-block;
}

.product-categories li span:hover {
  text-decoration: underline;
  color: #2b2b2b;
  font-weight: bold;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #669295;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.newProduct {
  border-spacing: 0 !important;
  width: 100%;
}
.newProduct img {
  display: block;
  margin: 0 auto;
}
.newProduct > tr:first-child {
  background: #f7f7f7;
}
.newProduct th {
  text-align: left;
  border: 1px solid #ececec;
  font-size: 11px;
  color: #7e7e7e;
  font-weight: 500;
  text-transform: uppercase;
}
.product-tab-table tr:nth-child(2n) {
  background: #fbfbfb;
}
.product-tab__price-parent {
  position: relative;
}
.product-tab__price-child {
  position: absolute;
  background: #f9f9f9;
  border: 1px solid #dedede;
  padding: 7px;
  margin-top: 2px;
  z-index: 1;
  box-shadow: 1px 0px 20px rgb(0 0 0 / 10%);
}
.product-tab__price-child > div {
  width: max-content;
  padding: 6px 7px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.product-tab__price-child > div:last-child {
  border: none;
}
.product-tab__price-child > div em {
  font-weight: 400;
  font-size: 13px;
  color: #c06969;
}
.product-tab__price-child > div:hover {
  background: #e8e8e8;
}

.newProduct td {
  padding: 10px 6px;
  border-left: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.newProduct td:last-child {
  border-right: 1px solid #ececec;
}

.newProduct a {
  font-size: 12px;
  text-decoration: underline;
}
.newProduct b {
  font-weight: bold;
  font-size: 12px;
}
.newProduct em {
  font-size: 12px;
}
.product-tab-close {
  color: #d37873;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}
.product-flax-container {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.newProduct input {
  margin-right: 5px;
  padding: 3px 5px !important;
  border-radius: 2px;
  height: 32px !important;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.newProduct input::-webkit-outer-spin-button,
.newProduct input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.productItemDelete {
  color: #c2263f !important;
  text-decoration: none !important;
  font-size: 20px !important;
  display: block !important;
  text-align: center !important;
  font-weight: bold !important;
  /*padding: 10px 0;*/
}
.productItemActions {
  display: flex;
  flex-direction: column;
  //overflow: hidden;
}
.productItemActions a {
  padding: 8px 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.productItemActions a:first-child {
  margin-top: -50px;
  opacity: 0;
}
.productItemActions a.visible {
  margin-top: inherit;
  opacity: 1;
}
.productItemActions a:after {
  right: 0px;
}

.productItemStatuses {
  position: relative;
}

.productItemStatusesDrop {
  position: absolute;
  top: 20px;
  left: 0;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
  z-index: 2;
  min-width: 200px;
  display: none;
}
.productItemStatusesTd:hover .productItemStatusesDrop {
  display: block !important;
}
.productItemStatusesDrop li {
  padding: 2px 0;
}
.productItemStatusesDrop a {
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
}
.productItemStatusesDrop a:hover {
  text-decoration: underline;
}

.productItemsTotalPrice {
  text-align: right;
  padding: 15px 0;
}
.productItemsTotalPrice em {
  color: #404040;
  font-size: 17px;
}
.productItemsTotalPrice b {
  color: #669295;
  font-weight: bold;
  font-size: 24px;
  margin-left: 7px;
}
.productItemsHeader {
  background: #fff;
  box-shadow: none;
  min-height: inherit;
  outline: none !important;
}
.productItemsHeader .is {
  background: #669295;
}
.productItemsHeader .toogle-list {
  display: block;
}
.productItemsHeader .toogle-list li {
  padding: 6px;
}
.productItemsHeader .toogle-list a {
  text-decoration: none;
}
.ProductItemNotInStock {
  background: #efefef;
}
.ProductItemNotInStockP {
  color: #c2263f;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 100ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 100ms;
}

.tabs-enter {
  opacity: 0;
}
.tabs-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.tabs-exit {
  opacity: 1;
}
.tabs-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}
.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.scale-exit {
  opacity: 1;
}
.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.slide-enter {
  opacity: 0;
  transform: translateX(-20px);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 500ms, transform 500ms;
}
.mdb {
  display: none;
}

.fade-enter {
  opacity: 0.01;
  position: relative;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
}

.fade-enter.fade-enter-done {
}

.fade-exit {
  opacity: 1;
  position: relative;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
}

.fade-exit.fade-exit-done {
}

.transition-group-screen {
  position: relative;
  height: 100vh;
  display: contents;
  /*width: 100vw;*/
}

/*.section-screen {
    position: absolute;
    height: inherit;
    width: inherit;
}*/

.leave-modal {
  background: rgba(000, 000, 000, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leave-modal-container {
  background: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  padding-bottom: 30px;
}
.leave-modal-title {
  color: #676767;
  font-weight: bold;
  font-size: 22px;
  margin: 8px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.leave-modal-description {
  color: #676767;
  font-size: 14px;
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
}
.leave-modal-confirm {
  background: #c1c1c1;
  border: none;
  color: #fff;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.leave-modal-cancel {
  background: #c2263f;
  border: none;
  color: #fff;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.product-feature {
  position: relative;
}
.leave-modal .comment-tabs,
.leave-modal .history-comment-editor {
  display: none;
}

.contactCard {
  margin-bottom: 10px !important;
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.contactCard img {
  max-width: 100px;
  border: 4px solid #dedede;
  padding: 5px;
  box-sizing: border-box;
}
.contactCard h3 {
  color: #555555;
  text-align: right;
  padding-top: 7px;
  padding-bottom: 3px;
}
.contact-left-col {
  position: relative;
}
.contactCard p {
  font-size: 12px;
  padding: 2px 0;
  color: #afafaf;
  text-align: right;
  font-family: Montserrat-Light;
}
.contact-left-col-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #669295;
  padding: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -45px;
  left: 5px;
  background: #f5f5f5;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.drop_zone__container {
  width: 100%;
  height: 150px;
  background: rgb(243, 243, 243);
  border: 1px dashed rgb(187, 187, 187);
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 !important;
}
.filesDropDown__zone {
  font-size: 14px;
  color: #4c4c4c;
  text-align: center;
  font-weight: normal;
}
.filesDropDown__zone em {
  font-size: 13px;
}
.filesDropDown__error-message {
  font-size: 14px;
  color: #d37873;
  display: block;
}
.new-task__tabs {
  display: flex;
}
.new-task__tabs a {
}

/*
https://sboxcrm.com/images/landing/logo-crm.png

Здравствуйте, Дорогой друг!
Чтобы подтвердить подписку на рассылку,
просто перейдите по ссылке ниже.

Подтвердить подписку

Если вы не подписывались, пожалуйста, проигнорируйте это письмо.
&/
 */

.contact-left-col-img.open-image {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  padding: 0;
  left: 0;
  top: 0;
}
.contact-left-col-img-close {
  width: 25px;
  position: absolute;
  z-index: 2;
  top: -52px;
  left: 70px;
  opacity: 0.8;
  cursor: pointer;
}
.kanban-board-delete-item {
  position: absolute;
  z-index: 1;
  top: 0;
  height: calc(100% - 6px);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(194, 38, 63, 0.8);
  border-radius: 0 6px 6px 0;
  right: -30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.kanbanItemParent:hover .kanban-board-delete-item {
  right: 0;
}
.kanban-board-delete-item i {
  font-size: 19px;
  color: #fff;
}
.contact-left-col input[type="file"] {
  display: none;
}
.contact-left-collabel {
  width: 100px;
}
.contactCardInfo {
  flex-grow: 1;
  padding: 0 10px;
}
.contactCardInfo input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dedede;
  height: 30px;
  margin-bottom: 5px;
  font-family: Montserrat-Regular;
  outline: none !important;
}

.fastAdd {
  position: relative;
}
.fastAddChild {
  background: #fff;
  padding: 5px 10px;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  float: left;
  margin-left: 5px;
}
.fastAddSave {
  width: 100%;
  background: #669295;
  border: none;
  padding: 11px;
  margin-top: 5px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat-Black", sans-serif;
  letter-spacing: 0.3px !important;
}
.fastAddSave:disabled {
  opacity: 0.5;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.react-kanban-board {
  /*height: calc(100vh - 85px);*/
  overflow-y: auto !important;
  min-height: 300px;
}
.react-kanban-board::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.react-kanban-board::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.react-kanban-board::-webkit-scrollbar-thumb {
  background-color: #669295;
}

.react-kanban-column {
  width: 300px;
  padding: 5px !important;
}

.kanbanItem {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.kanbanItem:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}
.kanbanItemStatuses {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  padding-bottom: 10px;
}
.kanbanItemStatus {
  border-radius: 2px;
  padding: 2px 7px;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 400;
}
.kanbanItemPriority {
  opacity: 0.6;
}
.kanbanItemDescription h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4c4c4c;
  overflow-wrap: anywhere;
}
.kanbanItemDescription p {
  font-family: Montserrat-Light, sans-serif;
  padding-top: 3px;
  font-size: 14px;
  color: #2b2b2b;
  display: none;
}
.kanbanItemFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.kanbanItemOwners {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.kanbanItemOwner {
  background: #669295;
  color: #fff;
  padding: 4px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 2px;
  width: 23px;
  height: 23px;
  text-align: center;
  box-sizing: border-box;
  line-height: 15px;
}
.kanbanItemFooterRight {
  display: flex;
  align-items: center;
}
.kanbanItemSubTask {
  margin-right: 15px;
}
.kanbanItemSubTask i,
.kanbanItemDate i {
  font-size: 15px;
  color: #7f919f;
  padding-right: 5px;
}
.kanbanItemSubTask span,
.kanbanItemDate span {
  font-size: 13px;
  color: #7f919f;
}
.kanbanMore {
  width: 100%;
  padding: 10px;
  border: none;
  /* background: #669295; */
  color: #669295;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: bold;
}
.is_overdue_task {
  color: #c96e6e !important;
  text-decoration: underline !important;
}
.comment-tabs {
  border: 1px solid #c3c3c3;
  display: table;
  border-radius: 4px;
}
.comment-tabs a {
  color: #919191;
  border-right: 1px solid #c3c3c3;
  display: inline-block;
  padding: 7px 19px;
  font-size: 12px;
}
.comment-tabs a:last-child {
  border: none;
}
.comment-tabs a.comment-tab-active {
  background: #669295;
  color: #ffffff;
}

@keyframes ldio-ss7moj7l4n-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ldio-ss7moj7l4n-2 {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(225deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.ldio-ss7moj7l4n > div:nth-child(2) {
  transform: translate(-15px, 0);
}
.ldio-ss7moj7l4n > div:nth-child(2) div {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 30px;
  border-radius: 60px 60px 0 0;
  background: #669295;
  animation: ldio-ss7moj7l4n-1 1s linear infinite;
  transform-origin: 30px 30px;
}
.ldio-ss7moj7l4n > div:nth-child(2) div:nth-child(2) {
  animation: ldio-ss7moj7l4n-2 1s linear infinite;
}
.ldio-ss7moj7l4n > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}
@keyframes ldio-ss7moj7l4n-3 {
  0% {
    transform: translate(95px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(35px, 0);
    opacity: 1;
  }
}
.ldio-ss7moj7l4n > div:nth-child(1) {
  display: block;
}
.ldio-ss7moj7l4n > div:nth-child(1) div {
  position: absolute;
  top: 46px;
  left: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c2263f;
  animation: ldio-ss7moj7l4n-3 1s linear infinite;
}
.ldio-ss7moj7l4n > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s;
}
.ldio-ss7moj7l4n > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s;
}
.ldio-ss7moj7l4n > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s;
}
.loadingio-spinner-bean-eater-92qvcha3hj {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: none;
  position: absolute;
  left: 47%;
  top: 40%;
}
.ldio-ss7moj7l4n {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.8);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ss7moj7l4n div {
  box-sizing: content-box;
}

.cssload-loader {
  left: calc(50% - 31px);
  width: 100px;
  height: 100px;
  perspective: 780px;
  position: absolute;
  top: 250px;
}

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  -o-animation: cssload-rotate-one 1.15s linear infinite;
  -ms-animation: cssload-rotate-one 1.15s linear infinite;
  -webkit-animation: cssload-rotate-one 1.15s linear infinite;
  -moz-animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid #669295;
}
.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  -o-animation: cssload-rotate-two 1.15s linear infinite;
  -ms-animation: cssload-rotate-two 1.15s linear infinite;
  -webkit-animation: cssload-rotate-two 1.15s linear infinite;
  -moz-animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid #c2263f;
}
.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  -o-animation: cssload-rotate-three 1.15s linear infinite;
  -ms-animation: cssload-rotate-three 1.15s linear infinite;
  -webkit-animation: cssload-rotate-three 1.15s linear infinite;
  -moz-animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid #f6d297;
}

.productItemsSaveAll {
  color: #fff !important;
  box-shadow: 0 10px 16px 0 rgba(000, 000, 000, 0.41);
  margin-top: 0;
  padding: 9px;
  text-decoration: none !important;
  margin-right: 5px;
}

.single-search {
  height: 40px;
  border: 1px solid #669295;
  background: #fff;
  box-sizing: border-box;
  margin-right: 5px;
  width: 320px;
  position: relative;
  border-radius: 4px;
}
.single-search-label {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  color: #669295;
  background: rgba(255, 255, 255, 0.9);
  top: -9px;
  left: 8px;
  padding: 0 8px;
  cursor: pointer;
}
.single-search-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.single-search-container div:first-child {
  flex-grow: 1;
}
.single-search-container i {
  font-size: 20px;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 6px;
  color: #669295;
}
.single-search input,
.single-search select {
  border: none !important;
  flex-grow: 1;
  height: 37px !important;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  outline: none !important;
  background: #fff !important;
}
.single-search .rsb {
  margin-top: 3px;
  background: #fff;
  border: none;
}

.single-search-sub-items {
  position: absolute;
  width: 100%;
  border: 1px solid #669295;
  padding: 5px 0;
  background: #fff;
  box-sizing: border-box;
  border-top: 0;
  top: 39px;
  left: 0;
  z-index: 1;
}
.single-search-sub-items a {
  color: #2b2b2b;
  width: 100%;
  display: block;
  padding: 4px 8px;
  box-sizing: border-box;
}
.single-search-sub-items a:hover {
  background: #efefef;
  font-weight: bold;
}
.input-child-link {
  position: absolute;
  right: 4px;
  bottom: -39px;
  background: none;
  border: none;
  font-size: 31px;
  color: #669295;
}
.input-child-not-active {
  position: absolute;
  right: 2px;
  bottom: -34px;
  background: none;
  border: none;
  font-size: 22px;
  color: #c2263f;
}
.input-child-not-active i:after,
.input-child-link i:after {
  right: 10px;
}

@keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.startDay {
  color: #fff;
  margin-right: 15px;
  border: 1px solid #fff;
  padding: 4px 15px;
  font-size: 15px;
  position: relative;
  top: -2px;
  cursor: pointer;
  width: 140px;
  display: inline-block;
  text-align: center;
  position: relative;
}
.subBlockEndTime {
  position: absolute;
  width: 500px;
  right: 0;
  height: 300px;
  display: none;
}
.startDayText {
  display: none;
}
.startDay:hover .startDayTime {
  display: none;
}
.startDay:hover .startDayText {
  display: block;
}

.second-table {
}
.second-table-white-td td {
  background: #ffffff;
}
.second-table table {
  border-spacing: 0;
}
.second-table tr:first-child {
}
.second-table th {
  text-align: left;
  padding: 15px;
  background: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-left: 0;
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400;
}
.second-table th:first-child {
  border-left: 1px solid #dcdcdc;
}
.second-table td {
  text-align: left;
  padding: 12px;
  border: 1px solid #dcdcdc;
  border-left: 0;
  vertical-align: middle;
}
.second-table td:first-child {
  border-left: 1px solid #dcdcdc;
}
.second-table__icon {
  font-size: 20px;
  text-align: center;
  display: block;
  color: #8e8e8e;
}
.second-table__icon-red {
  color: #c16a6a;
}
.second-table__icon-grey {
  color: #878787;
}
.second-table .btni {
  padding: 10px 18px;
  font-size: 13px;
}
.second-table h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 13px;
  color: #888888;
  padding-bottom: 3px;
  padding-left: 5px;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.hoverBox_4521 {
  display: none !important;
}
.react-joyride__tooltip div {
  font-size: 14px !important;
}
.file_message_upload img {
  cursor: pointer;
}
.file_message_upload {
  padding: 0 !important;
  position: absolute;
}
.file_message_upload__file {
  right: 38px;
  bottom: 6px;
}
.file_message_upload__image {
  right: 38px;
  top: 6px;
}
.example-preview {
  padding: 0 10px;
}
.timeline.timeline-5 .timeline-items {
  margin: 0;
  padding: 0;
}
.timeline.timeline-5 .timeline-items .timeline-item {
  margin-left: 0;
  padding: 0;
  position: relative;
  margin-top: 15px;
  cursor: pointer;
}
.timeline.timeline-5 .timeline-items .timeline-item:last-child {
  border-left-color: transparent;
  padding-bottom: 0;
}
.timeline.timeline-5 .timeline-items .timeline-item .timeline-media {
  position: absolute;
  top: 0;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 0;
  background: linear-gradient(45deg, #929292, #656565 47%, #948a8a);
}
.timeline-media i {
  color: #ffffff;
  font-size: 21px;
}
.timeline.timeline-5 .timeline-items .timeline-item .timeline-desc {
  padding: 9px 0 10px 44px;
}
.timeline.timeline-5 .timeline-items .timeline-item .timeline-desc::before {
  content: "";
  position: absolute;
  width: 4px;
  height: calc(100% - 32px);
  background-color: #ebedf3;
  border-radius: 6px;
  top: 40px;
  left: 16px;
}
.timeline.timeline-5
  .timeline-items
  .timeline-item
  .timeline-desc.timeline-desc-light-primary::before {
  background: linear-gradient(45deg, #929292, #656565 47%, #948a8a);
}
.timeline-item:last-child .timeline-desc:before {
  display: none !important;
}
.text-primary {
  color: #656565;
  font-weight: bold;
  display: block;
  margin-top: -5px;
}
.text-dark-50 {
  /*color: #7e8299;*/
  color: #afafaf;
  font-size: 13px;
}

.timeline-item-completed .timeline-media,
.timeline-item-completed .timeline-desc-light-primary::before {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
}
.timeline-item-completed .text-primary {
  color: #669295;
}

.timeline-item-work .timeline-media,
.timeline-item-work .timeline-desc-light-primary::before {
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a) !important;
}
.timeline-item-work .text-primary {
  color: #c16a6a;
}
.timeline-item-complete {
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  border-radius: 6px;
  width: 350px;
  padding: 14px 10px;
  margin-top: 13px;
  display: flex;
  color: #ffffff;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.timeline-item-complete i {
  font-size: 25px;
  padding-right: 15px;
  padding-left: 5px;
}
.timeline-item-complete span {
  display: block;
  font-weight: bold;
}
.timeline-item-complete em {
  font-size: 13px;
}
.video-desc-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0;
}
.video-desc-container i {
  padding-right: 10px;
}
.video-desc-container p,
.video-desc-container a {
  flex-grow: 1;
}
.video-desc-container button {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 10px 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.educate-progress-parent {
  display: flex;
  flex-direction: column;
  width: 200px;
  float: left;
  margin-top: 16px;
  margin-right: 10px;
  cursor: pointer;
}
.educate-progress-container {
  width: 200px;
  height: 23px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  border-radius: 15px;
}
.educate-progress-child {
  background: #ffffff;
  height: 19px;
  margin: 2px;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
}
.educate-progress-parent span {
  color: #444444;
  font-size: 11px;
  text-align: center;
  display: block;
  padding-top: 1px;
  margin-top: -20px;
  /*text-shadow: 0px 0px 1px #444, 0 0 0em #444;*/
}
.educate-progress-parent:after {
  width: 101%;
  box-sizing: border-box;
  font-size: 11px !important;
  text-align: center;
  border: 0;
  padding: 0;
  padding-top: 5px;
  border-radius: 15px;
  left: 0px;
  height: 24px;
  top: 0;
}
.paybutton .btn {
  padding: 11px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: linear-gradient(45deg, #d37873 0%, #c96e6e 47%, #c16a6a 100%);
  border-color: #5867dd;
  border: none;
  color: #fff;
  box-shadow: 0 4px 11px 0 rgba(000, 000, 000, 0.3);
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-top: 10px;
  margin-left: 5px;
}
.comment-item {
  padding: 10px 0;
  border-bottom: 1px solid #cfcfcf;
}
.comment-item .editBlc {
  background: none;
  box-shadow: none;
  padding: 0;
  margin-top: 5px;
}
.comment-item a {
  text-decoration: underline;
}
.comment-item__content {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.comment-item__content-right {
  font-size: 12px;
  color: #888888;
  min-width: 100px;
  padding-left: 10px;
  text-align: right;
}
.comment-item__content-left {
  flex-grow: 1;
}
.comment-item__content-left p {
  font-size: 14px;
}
.comment-item__reply_to_content {
  font-size: 13px;
  background: #efefef;
  padding: 10px;
  margin-left: 5px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 8px;
}
.comment-item__answer-button {
  font-size: 10px;
  margin-top: 7px;
  display: block;
  text-transform: uppercase;
  color: #c06969;
}
.comment-item__reply_to_content-cancel {
  color: #c06969;
  display: block;
  margin-top: 3px;
  text-decoration: underline;
}

.created-entity-link {
  font-size: 15px;
  display: block;
  padding: 9px;
  text-transform: capitalize;
  text-decoration: underline;
}

@media screen and (max-width: 1260px) {
  .contact-left {
    width: 30%;
  }
  .contact-page .contact-right-col {
    width: 69%;
  }
}
@media screen and (max-width: 1150px) {
  .contact-left {
    width: 100%;
  }
  .contact-page .contact-right-col {
    width: 100%;
  }
  .btni {
    padding: 9px 14px;
    font-size: 12px;
  }
  .btns.btn-inside {
    height: 33px;
    line-height: 15px;
  }
  .btns.btn-inside i {
    font-size: 16px;
  }
}

@media screen and (max-width: 940px) {
  .navbar-header,
  .sidebar {
    width: 60px;
  }
  .navbar > ul {
    width: 50px;
    margin-left: 5px;
    margin-top: 5px;
  }
  .navbar > ul > li > a > i {
    width: 40px;
    height: 27px;
    font-size: 23px;
    line-height: 27px;
    margin-left: 3px;
  }
  .navbar > ul > li > a span {
    display: none;
  }
  .user-info > img {
    margin: 4px;
    width: 50px;
  }
  .user-sb-name {
    display: none;
  }
  .user-info {
    height: 60px;
  }
  .page {
    margin: 75px 0 0 75px;
    width: calc(100% - 90px);
  }
  navbar > ul > li:hover span {
    display: inline-block;
    text-align: left;
  }
  .navbar > ul > li:hover {
    width: 240px;
  }
  .navbar > ul > li:hover > a {
    background: #669295;
    color: #fff;
  }
  .navbar > ul > li:hover > a i {
    color: #fff;
  }
  .stic .navbar-header,
  .stic .sidebar {
    width: 270px;
  }
  .stic .navbar > ul {
    width: inherit;
    margin-left: inherit;
    margin-top: inherit;
  }
  .stic .navbar > ul > li > a > i {
    width: 27px;
    height: 27px;
    font-size: 23px;
    line-height: 27px;
    margin-left: 3px;
  }
  .stic .navbar > ul > li > a span {
    display: inline-block;
  }
  .stic .user-info > img {
    margin: 4px;
    width: 50px;
    display: none;
  }
  .stic .user-sb-name {
    display: block;
  }
  .stic .user-info {
    height: 60px;
  }
  .stic .page {
    margin: 75px 0 0 75px;
    width: calc(100% - 90px);
  }
  .stic .navbar > ul > li:hover span {
    display: inline-block;
    text-align: left;
  }
  .stic .navbar > ul > li:hover {
    width: 240px;
  }
  .stic .navbar > ul > li:hover > a {
    background: #669295;
    color: #fff;
  }
  .stic .navbar > ul > li:hover > a i {
    color: #fff;
  }
  .educate-progress-parent {
    display: none !important;
  }
  .feed {
    display: flex;
    flex-direction: column-reverse;
  }
  .feed .rc8 {
    float: none;
    width: calc(100% - 40px);
  }
  .feed .rc4 {
    float: none;
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 721px) {
  .setting-nav .btni {
    display: none;
  }
  .tariffItem {
    width: 100%;
    margin-bottom: 10px;
  }
  .tariffform-contain {
    overflow: auto;
  }
  .tariffform-contain::-webkit-scrollbar {
    height: 5px;
  }
  .mdb {
    display: block !important;
  }
  .mobile-close {
    transform: rotate(45deg);
    color: #ffffff;
    display: block;
    font-size: 21px;
    position: relative;
    top: -1px;
    left: 2px;
  }
  .mobile-block {
    display: block !important;
    float: left;
  }
  .mobile-block > a,
  .butt-group a.btni,
  .butt-group a.btns,
  .butt-group button,
  .about-icon {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    float: left;
  }
  .butt-group > button span,
  .butt-group > a span,
  .print-block span {
    display: none;
  }
  .butt-group-card button,
  .butt-group-card a {
    margin: 0 !important;
    margin-right: 5px !important;
  }
  .butt-group-card i {
    font-size: 20px !important;
  }
  .about-icon i {
    font-size: 21px !important;
  }
  .butt-group-card-wrap {
    float: right !important;
  }
  .task-statuses {
    flex-wrap: wrap;
    border: none;
  }
  .task-statuses button {
    min-width: 87px;
    border: 1px solid #dedede !important;
    margin: 2px;
    max-width: 140px;
  }
  .product-table-mobile {
    overflow: auto;
    margin-bottom: 15px;
  }
  .product-table-mobile::-webkit-scrollbar {
    height: 5px;
  }
  .mdn {
    display: none !important;
  }
  .stocktop {
    display: none !important;
  }
  .toogle-bottom {
    width: inherit !important;
  }
  .bpmModal .right-modal {
    width: 100%;
  }
  .butt-group button,
  .butt-group a {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 6px;
    box-shadow: none;
  }
  .btns.btn-inside {
    box-shadow: none;
  }
  .fl-r {
    width: 100%;
  }
  .aab {
    width: 100%;
  }
  /*.toogle-bottom {
        display: none;
    }*/
  .tabs-header li {
    text-align: center;
  }
  .tWrap,
  .sp-table {
    overflow-x: auto;
    display: block;
    width: 100% !important;
  }
  .contact-page td {
    white-space: nowrap;
  }
  .btns {
    padding: 5px;
    margin-right: 0px;
  }
  .mail-left,
  .mailButton {
    width: 100% !important;
  }
  .mailleft,
  .mailbody {
    width: 100% !important;
  }
}

@media screen and (max-width: 641px) {
  .rc6 {
    width: 100%;
  }
  .taskdiv {
    width: 100%;
  }
  .navbar-header,
  .sidebar {
    width: 45px;
  }
  .navbar > ul {
    width: 45px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .navbar > ul > li > a {
    padding: 8px 0;
    text-align: center;
  }
  .navbar > ul > li > a > i {
    width: 40px;
    height: 27px;
    font-size: 21px;
    line-height: 27px;
    margin-left: 0px;
  }
  .user-info > img {
    margin: 0px;
    width: 45px;
    margin-top: 3px;
  }
  .user-info {
    height: 48px;
  }
  .page {
    margin: 65px 0 0 50px;
    width: calc(100% - 50px);
  }
  .filter-div {
    width: 100%;
    margin-right: 5px;
  }
  .navbar-brand i {
    font-size: 23px;
    top: 0px;
    margin: 13px 8px 0 10px;
  }
  header {
    height: 45px;
  }
  .nav-right > a {
    margin: 9px 0px 0px 10px;
  }
  header .light-logo {
    max-width: 134px;
    margin-top: 14px;
  }
  .user-info {
    margin-top: 45px;
  }
  .stic .icon-right {
    display: none;
  }
  .navbar > ul > li > a {
    padding: 8px 10px 8px 10px;
    text-align: left;
  }
  .icon-right a {
    margin: 12px 6px;
  }
  .smn {
    display: none !important;
  }
  .contact-page .tab-content,
  .contact-page .contact-left-col {
    padding: 7px;
  }
  .rc3 {
    width: 50%;
  }
  .rmod-header b {
    width: 60%;
  }
}

@media screen and (max-width: 541px) {
  .right-modal {
    width: 100%;
  }
  .icon-right {
    margin-right: 5px;
  }
  .icon-right a i {
    font-size: 18px;
  }
  .spImg {
    width: 21px;
  }
  .sidebarLogin {
    width: 100%;
  }
  .xsn {
    display: none !important;
  }
  .rc4,
  .rc2 {
    width: 49.3%;
  }
  .col4,
  .col8 {
    width: 100%;
    box-sizing: border-box;
  }
  .periodGroup {
    top: 50px;
  }
  .icon-right .toogle-bottom .toogle-list a {
    margin: 0 6px;
  }
  .ReactApexChart {
    width: 100% !important;
  }
  .newDashboard .col6 {
    padding: 10px;
  }
  .newDashboard span {
    font-size: 13px;
    line-height: 16px;
  }
  .plusbackground {
    padding: 10px;
  }
  #apexchartsk5xpnwpw {
    width: 100% !important;
  }
  .filterBlock .rc4,
  .filterBlock .rc8 {
    width: 100%;
  }
  .filterBlock .btn-m {
    background: #669295 !important;
    margin-left: 0px !important;
    padding: 5px 0 !important;
  }
  .filterBlock .btn-m i {
    color: #fff !important;
  }
  .sp-table label {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .single-search {
    width: 100%;
  }
  .contact-page .tab-content {
    overflow: auto;
  }
}

@media screen and (max-width: 360px) {
}

#switch {
  width: 75px;
  height: 40px;
  float: right;
}

.toggle {
  position: relative;
  border: 2px solid #717171;
  border-radius: 0;
  -webkit-transition: border-color 0.6s ease-out;
  transition: border-color 0.6s ease-out;
  box-sizing: border-box;
}

.toggle.toggle-on {
  border-color: rgba(102, 146, 149, 0.5);
  -webkit-transition: all 0.5s 0.15s ease-out;
  transition: all 0.5s 0.15s ease-out;
}

.toggle-button {
  position: absolute;
  top: 4px;
  width: 28px;
  bottom: 4px;
  right: 39px;
  background-color: #717171;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
  transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
}

.toggle-on .toggle-button {
  top: 3px;
  width: 65px;
  bottom: 3px;
  right: 3px;
  border-radius: 0;
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295 100%
  ) !important;
  box-shadow: 0 0 16px #4b7a8d;
  -webkit-transition: all 0.2s 0.1s, right 0.1s;
  transition: all 0.2s 0.1s, right 0.1s;
}

.toggle-text-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  color: rgba(0, 0, 0, 0);
}

.toggle-on .toggle-text-on {
  color: #ffffff;
  -webkit-transition: color 0.3s 0.15s;
  transition: color 0.3s 0.15s;
}

.toggle-text-off {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  line-height: 36px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  cursor: pointer;
  color: #717171;
}

.toggle-on .toggle-text-off {
  color: rgba(0, 0, 0, 0);
}

/* used for streak effect */
.glow-comp {
  position: absolute;
  opacity: 0;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 0;
  background-color: rgba(75, 122, 141, 0.1);
  box-shadow: 0 0 12px rgba(75, 122, 141, 0.2);
  -webkit-transition: opacity 4.5s 1s;
  transition: opacity 4.5s 1s;
}

.toggle-on .glow-comp {
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.first_step {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(000, 000, 000, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-modal {
  background: #ffffff;
  width: 700px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.support-modal__left {
  /* width: 60%; */
  padding: 15px 15px 0 15px;
}
.support-modal__left h2 {
  font-weight: 900;
  font-size: 19px;
  color: #5a5a5a;
  margin: 3px 0 10px 0;
}
.support-modal__left ul {
  margin-left: 20px;
  padding-top: 5px;
}
.support-modal__left li {
  list-style: disc;
}
.support-modal__left p {
  font-size: 14px;
  font-weight: 300;
  color: #5a5a5a;
  margin-top: 0;
  margin-bottom: 30px;
}
.support-modal__left button {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
}
.support-modal__right {
  width: 35%;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  /*height: 350px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 230px;
}
.support-modal__right img {
  width: 212px;
  margin: 0 auto;
  display: block;
  margin-top: 15px;
}
.support-modal__right p {
  color: #ffffff;
  font-weight: 400;
  font-size: 13px;
  padding: 7px 10px;
  text-align: center;
  /* text-shadow: 0px 0px 2px white, 0 0 0px white; */
  margin: 0;
  margin-bottom: 10px;
}
.support-modal__right p a {
  color: #fff;
  display: block;
  margin-top: 7px;
}
.navbar__li-bottom {
  background: linear-gradient(
    45deg,
    #929292 0%,
    #656565 47%,
    #948a8a 100%
  ) !important;
}
.navbar__li-bottom:hover {
  opacity: 0.8;
}
.navbar__li-bottom a:hover {
  background: none !important;
}
.navbar__li-bottom span,
.navbar__li-bottom i {
  color: #ffffff !important;
}
.sidebar {
  overflow: auto !important;
}
.full-width-button {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #ffffff;
  display: block;
  text-align: center;
  margin-top: 7px;
  padding: 10px;
}
.full-width-button-grey {
  background: linear-gradient(45deg, #929292 0%, #656565 47%, #948a8a 100%);
}
.full-width-button-cancel {
  background: linear-gradient(45deg, #d37873 0%, #c96e6e 47%, #c16a6a 100%);
}
.full-width-button:hover {
  opacity: 0.8;
}
.newbg {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
}
.slim-link {
  display: block;
  text-align: center;
  padding: 8px;
  color: #c16a6a;
  text-decoration: underline;
  font-weight: bold;
}
.chatra--webkit {
  display: none !important;
}
.chatra--expanded {
  display: block !important;
}
.emptyLabelPadding.sbform label {
  padding-top: 0 !important;
}
.products-contents > div {
  display: contents;
}

.new-filter-item {
  font-size: 13px;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 10px;
  border: 2px solid #669295;
  margin-right: 4px;
  margin-bottom: 4px;
  box-shadow: 0 0 27px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  box-sizing: border-box;
  border-radius: 4px;
}
.new-filter-item:after {
  top: 37px;
  left: 0;
}
.new-filter-item em {
  display: inline-block;
  padding: 0 4px;
  color: #747474;
  font-size: 12px;
}
.new-filter-item-close {
  font-size: 20px;
  margin-left: 11px;
  color: #c16a6a;
}
.create-filter {
  font-size: 30px;
  color: #669295;
  cursor: pointer;
  position: relative;
}
.create-filter-sublist {
  position: absolute;
  border: 2px solid #669295;
  background: #fff;
  top: 38px;
  padding: 7px 7px 0 7px;
  max-height: 200px;
  width: 200px;
  overflow: auto;
  z-index: 20;
  border-radius: 4px;
}
.create-filter-sublist-item {
  font-size: 13px;
  padding: 4px 0;
}
.create-filter-sublist-item:hover {
  text-decoration: underline;
  background: #f7f7f7;
}
.create-filter-modal {
  /*position: absolute;*/
  border: 2px solid #669295;
  padding: 7px;
  background: #fff;
  top: 40px;
  right: -2px;
  width: 300px;
  z-index: 2;
  border-radius: 4px;
}
.create-filter-modal p {
  font-size: 14px;
  text-decoration: underline;
}
.create-filter-modal-options {
}
.create-filter-modal-option {
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
}
.create-filter-modal-option span {
  font-size: 14px;
  color: #2b2b2b;
  margin-left: 3px;
}
.create-filter-modal-option-circle {
  height: 14px;
  width: 14px;
  background: #ffffff;
  border-radius: 50%;
  border: 2px solid #6a6868;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-filter-modal-option-circle-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6a6868;
}
.create-filter-modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.create-filter-modal-actions-cancel {
  background: none;
  font-weight: 400;
  color: #999999;
  border: 1px solid #999999;
  padding: 9px 20px;
  font-family: Montserrat, sans-serif;
  border-radius: 4px;
}
.create-filter-modal-actions-save {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #ffffff;
  border: none;
  padding: 9px 20px;
  font-family: Montserrat, sans-serif;
  border-radius: 4px;
}
.create-filter-modal-actions-save:disabled {
  opacity: 0.5;
  cursor: default;
}
.create-filter-modal-valid {
  display: block;
  font-size: 12px;
  color: #d37873;
}
.listContext {
  position: absolute;
  background: #ffffff;
  z-index: 5;
  box-shadow: 0 0 27px rgb(0 0 0 / 20%);
  padding: 5px 10px;
  border: 2px solid #669194;
}
.listContext li {
  background: #ffffff;
  list-style: none;
  font-size: 13px;
  line-height: 22px;
}
.listContext li:hover {
  text-decoration: underline;
  cursor: pointer;
}
.newList-btn-group {
  display: flex;
  justify-content: space-between;
}
.newList-btn-group-right,
.newList-btn-group-left {
  display: flex;
  align-items: center;
}
.newList-btn-group-right li {
  list-style: none;
}
.active-tr {
  outline: 2px solid #d37873 !important;
}
.list-is-empty {
  font-size: 14px;
  color: #7c7c7c;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.toogle-list-sort {
  /*padding: 6px 10px !important;*/
  cursor: pointer;
}
.toogle-list-sort b,
.toogle-list-sort i {
  color: #2b2b2b !important;
}
.toogle-list-sort:hover b {
  color: #ffffff !important;
}
.toogle-list-sort a {
  display: flex;
  align-items: center;
}
.toogle-list-sort i {
  font-size: 15px !important;
  margin-right: 5px;
  color: #2b2b2b !important;
}
.toogle-list-sort-simple {
}
.toogle-list-sort-rotate {
  margin-top: -7px;
  transform: rotate(180deg);
  display: inline-block;
}
.toogle-list-sort b {
  font-size: 13px !important;
}
.toogle-list-active {
  background: #d37873;
}
.toogle-list-active b,
.toogle-list-active i {
  color: #ffffff !important;
}
.form-container {
  display: flex;
  flex-wrap: wrap;
}
.nowrap-list-column {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.w20 {
  width: 20%;
}
.w20-p {
  width: calc(20% - 10px);
  padding: 0 5px;
}
.w25 {
  width: 25%;
}
.w25-p {
  width: calc(25% - 10px);
  padding: 0 5px;
}
.w30 {
  width: 30%;
}
.w30-p {
  width: calc(30% - 10px);
  padding: 0 5px;
}
.w33 {
  width: 33.3%;
}
.w40 {
  width: 40%;
}
.w40-p {
  width: calc(40% - 10px);
  padding: 0 5px;
}
.w50 {
  width: 50%;
}
.w50-p {
  width: calc(50% - 10px);
  padding: 0 5px;
}
.w60 {
  width: 60%;
}
.w60-p {
  width: calc(60% - 10px);
  padding: 0 5px;
}
.w66 {
  width: calc(66% - 10px);
}
.w70 {
  width: 70%;
}
.w70-p {
  width: calc(70% - 10px);
  padding: 0 5px;
}
.w75-p {
  width: calc(75% - 10px);
  padding: 0 5px;
}
.w80 {
  width: 80%;
}
.w100 {
  width: 100%;
}
.w100-p {
  width: calc(100% - 10px);
  padding: 0 5px;
}

.comment-item__owner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment-item__owner em {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: #818181;
  margin-right: 10px;
}
/*.listContext-delete {*/
/*    width: 100%;*/
/*    background: #d37873;*/
/*    font-size: 13px;*/
/*    color: #FFFFFF;*/
/*    border: none;*/
/*    padding: 5px 25px;*/
/*}*/

.full-modal {
  /*background: rgba(000,000,000,0.5);*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.full-modal-container {
  width: 1060px;
  position: relative;
  z-index: 60;
}
.full-modal-header {
  height: 33px;
  width: 100%;
  background: #f1f5f9;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-modal-header-right {
  display: flex;
  align-items: center;
}
.full-modal-header-close {
  width: 24px;
  height: 24px;
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 6px;
  cursor: pointer;
}
.full-modal-body {
  display: flex;
}
.full-modal-left {
  background: #f1f5f9;
  padding: 15px;
  width: 770px;
}
.full-modal-header-left {
  width: 770px;
  display: flex;
  justify-content: space-between;
}
.full-modal-left .tox-statusbar {
  display: none !important;
}
.full-modal-left .tox-tinymce {
  margin-top: 10px;
  border: 1px solid #e5e9ec !important;
  box-shadow: 1px 0 24px rgb(0 0 0 / 4%);
}
.full-modal-left svg {
  fill: #969ea4 !important;
}
.full-modal-left .tox-tbtn__select-label {
  color: #969ea4 !important;
}
.full-modal-left input {
  background: #ffffff !important;
  height: 40px !important;
  border: 1px solid #e5e9ec !important;
  box-shadow: 1px 0 24px rgb(0 0 0 / 4%);
}
.full-modal-left input::placeholder {
  font-size: 14px !important;
  color: #969ea4 !important;
  font-weight: normal;
}
.full-modal-right {
  background: #ffffff;
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subtask-checkbox {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8eaee;
  background: #ffffff;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 1px 0 24px rgb(0 0 0 / 13%);
}
.subtask-checkbox.subtask-checkbox-active {
  border: none;
  background: linear-gradient(
    45deg,
    #80aeba 2%,
    #699499 46%,
    #669295
  ) !important;
}
.subtask-checkbox.subtask-checkbox-active + span {
  text-decoration: line-through;
}
.subtask-checkbox img {
  width: 12px;
}
.subtask-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.subtask-item:after {
  top: 24px;
}
.subtask-item span {
  color: #5c676c;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
}
.new-task-subtask {
  border-left: 1px solid #e5e9ec;
  padding: 6px 0 6px 14px;
  margin-top: 10px;
}
.new-task-subtask h4 {
  font-size: 12px;
  color: #7e7e7e;
}
.new-task-subtask input {
  height: 35px !important;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 1px 0 24px rgb(0 0 0 / 4%);
}
.new-task-subtask input::placeholder {
  font-size: 12px !important;
}

.new-task-status {
  position: relative;
  margin: 16px 10px;
}
.new-task-status-current {
  color: #ffffff;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
}
.new-task-status-current-icon {
  margin-left: 20px;
}
.new-task-status-current-icon i {
  display: block;
  transition: all 0.5s ease-out 0s;
  transform: rotate(270deg);
}
.new-task-status-current-icon-rotate {
  transform: rotate(90deg) !important;
}
.new-task-status-items {
  position: absolute;
  left: 0;
  top: 45px;
  min-width: 200px;
  border: 1px solid #e3e7ea;
  border-radius: 7px;
  box-shadow: 1px 0 24px rgb(0 0 0 / 12%);
  z-index: 2;
}
.new-task-status-item {
  font-size: 11px;
  padding: 12px 20px;
  cursor: pointer;
  text-transform: uppercase;
  background: #fff;
  border-bottom: 1px solid #e3e7ea;
}
.new-task-status-item:last-child {
  border: none;
}
.new-task-status-item:hover {
  background: #f6f6f6;
  border-left: 5px solid #669295;
}
.full-modal-right-hr {
  width: 100%;
  border-top: 1px solid #e9e9e9;
}
.full-modal-right-status {
  margin: 6px 10px;
  display: flex;
  align-items: center;
}
.full-modal-right-status span {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  color: #7c7c7c;
  margin-right: 15px;
}
.full-modal-right-status .new-task-status {
  margin: 0;
  flex-grow: 1;
}
.full-modal-right-status .new-task-status-current {
  background: rgb(239, 111, 0);
}

.user-dropdown-current {
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  flex-shrink: 0;
}
.user-dropdown-current-min {
  font-size: 11px;
  margin-right: 6px;
}
.full-modal-right-date {
  display: flex;
  padding: 3px 10px;
  border-radius: 6px;
  align-items: center;
}
.full-modal-right-date i {
  font-size: 26px;
  color: #669295;
}
.full-modal-right-date input {
  border: none !important;
  padding: 7px;
  font-size: 15px;
  padding-left: 11px;
  color: #6c6c6c;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.full-modal-right-date input:focus {
  outline: none !important;
}
.full-modal-right-date-button {
  border: none;
  font-size: 14px;
  padding: 7px 0px;
  margin-left: 10px;
  border-radius: 4px;
  color: #689397;
  background: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.full-modal-right-priority .new-task-status-current {
  padding: 7px 15px !important;
  font-size: 13px;
}
.full-modal-right-priority .new-task-status-current + .new-task-status-items {
  top: 33px;
}
.full-modal-right-buttons {
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
  margin-right: 10px;
}
.full-modal-right-buttons-delete {
  color: #ffffff;
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  padding: 14px 28px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.full-modal-right-buttons-save {
  color: #ffffff;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  padding: 14px 28px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  border: none;
  margin-left: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.full-modal-right-buttons-save:disabled {
  opacity: 0.5;
  cursor: auto;
}
.user-dropdown-current-single {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-dropdown-current-single em {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #679296;
  font-weight: bold;
  margin-left: 6px;
  text-transform: capitalize;
}
.full-modal-right-buttons-cancel {
}
.viewers {
  position: relative;
}
.viewers-container {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  color: #fff;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.viewers-container i {
  font-size: 23px;
}
.viewers-container em {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin-right: 8px;
}
.viewers-dropdown {
  position: absolute;
  right: 0;
  top: 25px;
  min-width: 260px;
  border: 1px solid #c6c6c6;
  border-radius: 7px;
  box-shadow: 1px 0 24px rgb(0 0 0 / 20%);
  z-index: 2;
  background: #ffffff;
}
.viewers-is-view {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
}
.viewers-is-view i {
  font-size: 24px;
  color: #5c676c;
  margin-right: 8px;
}
.viewers-is-view span {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #5c676c;
}
.viewers-list {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #5c676c;
  text-align: center;
  padding: 10px 0;
}
.viewers-list-data {
}
.viewers-list-empty {
  padding: 10px;
}
.viewers-update input {
  background: #eee !important;
}

.modal-loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}

.full-modal-right-attach-buttons {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}
.full-modal-right-attach-buttons > div {
  display: flex;
  background: #ffffff;
  border: 1px solid #e6eaed;
  width: fit-content;
  align-items: center;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.full-modal-right-attach-buttons i {
  font-size: 20px;
  margin-right: 5px;
  color: #7d858e;
}
.full-modal-right-attach-buttons span {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #7d858e;
}
.full-modal-right-attach-buttons > div:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}
.react-datepicker__input-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.btn-border-radius {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  border-radius: 4px;
  padding: 10px 35px;
  margin-top: 5px;
  font-size: 13px;
}
.full-modal-left-scrolled-block {
  max-height: 500px;
  overflow: auto;
}
.new-task-files .sp-table {
  display: none !important;
}
.new-task-files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.new-task-files-item {
  border: 2px solid #669295;
  border-radius: 5px;
  padding: 6px 16px;
  font-weight: bold;
  margin: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.new-task-files-item i {
  font-size: 18px;
  margin-right: 4px;
}
.selected-options-badges {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
}
.c-parent {
  display: flex;
}
.c-child {
  background: #bbb;
  margin: 10px;
}
.kanban-cols::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.kanban-cols::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.kanban-cols::-webkit-scrollbar-thumb {
  background-color: #669295;
  outline: 1px solid slategrey;
}
.kanban-cols-item {
  flex-shrink: 0;
  /*flex-grow: 1;*/
}
.modal-switch {
  display: flex;
  align-items: center;
}
.modal-switch #switch {
  height: 20px !important;
  width: 38px;
  border-radius: 5px;
}
.modal-switch .toggle-button {
  width: 15px;
  right: 15px;
  top: 3px;
  bottom: 3px;
  border-radius: 3px;
}
.toggle-on .toggle-button {
  right: 4px;
}
.modal-switch em {
  font-size: 13px;
  color: #717171;
  margin-right: 4px;
  margin-left: 10px;
  font-weight: bold;
}
.modal-switch .toggle-text-on {
  font-size: 0 !important;
}
.modal-switch .toggle-text-off {
  font-size: 0 !important;
}
.full-modal-task-tags {
  margin-left: 10px;
  display: flex;
}
.full-modal-task-tag-item {
  font-size: 11px;
  padding: 4px 12px;
  border-radius: 4px;
  color: #fff;
  background: #717171;
  cursor: pointer;
  margin-right: 4px;
}
.input-hidden {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 0;
  outline: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}
/*.full-modal-task-tags .rmod-header, .full-modal-task-tags label{
    display: none;
}
.full-modal-task-tags .AutoSelectParent  {
    min-height: 28px !important;
    height: 28px !important;
    min-width: 100px !important;
}*/
.sbox-autoselect {
  position: relative;
}
.sbox-autoselect-container {
  background-clip: padding-box;
  border: 1px solid #dedede;
  font-size: 13px;
  box-sizing: border-box;
  width: 100%;
  background: #f9f9f9;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #2b2b2b;
  height: 35px;
  border-radius: 4px;
}
.sbox-autoselect-container input {
  outline: none !important;
  border: navajowhite;
  background: none;
  width: 100%;
  padding: 10px 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.sbox-autoselect label {
}
.sbox-autoselect__dropdown {
  position: absolute;
  width: 100%;
  z-index: 5;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  max-height: 200px;
  border: 1px solid #d5d5d5;
  top: 35px;
  left: 0;
}
.sbox-autoselect__dropdown-top {
  bottom: 40px !important;
  top: inherit !important;
}
.sbox-autoselect__dropdown-item {
  background: #fff;
  cursor: pointer;
  padding: 7px 10px;
  color: #5e5e5e;
  font-size: 14px;
}
.sbox-autoselect__dropdown-item:hover {
  background: #dbdbdb;
}
.sbox-autoselect-text {
  font-size: 13px;
  padding: 8px 10px;
  font-weight: 400;
  display: block;
  text-overflow: ellipsis;
  max-width: 85%;
  overflow: hidden;
  white-space: nowrap;
}
.sbox-autoselect__dropdown-item-clear {
  background: #ffe7e7;
  color: #c36b6b;
  font-weight: 500;
}
.sp-table-tr-item:after {
  left: 0 !important;
  top: 30px;
  white-space: pre-wrap;
}
.task-tags-view .success {
  font-size: 11px;
  padding: 4px 12px;
  border-radius: 4px;
  color: #fff !important;
  background: #717171 !important;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 10px;
}
.task-tags-view .tagsName__dropdown {
  top: 23px;
  left: 5px;
}
.can-delete {
  background: #ffffff;
  width: 100%;
  padding: 15px 10px;
}
.can-delete-heading h1 {
  font-family: Montserrat-Black, sans-serif;
  color: #5e5e5e;
  font-size: 20px;
  text-align: center;
}
.can-delete-heading p {
  font-family: Montserrat-Light, sans-serif;
  font-size: 14px;
  margin-top: 3px;
  color: #5e5e5e;
  text-align: center;
}
.can-delete-list {
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
  padding: 0 10px;
  margin-top: 15px;
}
.chats-container {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 9;
  align-items: end;
}
.chat-item__fullSizeModal {
  height: 700px !important;
  width: 600px !important;
}
.chat-item {
  height: 420px;
  width: 350px;
  margin-right: 15px;
  box-shadow: 0 4px 11px 0 rgb(0 0 0 / 30%);
  background: #ffffff;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-out;
}
.chat-item__header {
  height: 50px;
  border-bottom: 1px solid #cecece;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 8px 8px 0 0;
  flex-shrink: 0;
  position: relative;
}
.chat-item__header-show {
  transform: rotate(-90deg);
  display: block;
  font-size: 16px;
  margin-left: 10px;
  color: #a7a7a7;
}
.chat-item__user {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
.chat-item__avatar {
  display: flex;
  align-items: center;
}
.chat-item__avatar img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 100px;
}
.chat-item__name {
  margin-left: 10px;
  overflow: hidden;
}
.chat-item__name h4 {
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.chat-item__name b {
  font-size: 12px;
  display: block;
}
.chat-item__buttons {
  display: flex;
}
.chat-item__buttons-close {
  font-size: 20px;
  margin-right: 10px;
  color: #606060;
  cursor: pointer;
}
.chat-item__buttons-hide {
  font-size: 21px;
  margin-right: 15px;
  color: #606060;
  cursor: pointer;
  font-weight: bold;
}
.chat-item__header-dropDown {
  position: absolute;
  background: #ffffff;
  top: 50px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  border-radius: 0 0 6px 0;
  padding: 5px 0;
}
.chat-item__header-dropDown button {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  color: #606060;
  border: none;
  background: none;
  display: block;
  padding: 7px 10px;
  width: 100%;
  text-align: left;
}
.chat-item__header-dropDown button:hover {
  background: #e5e5e5;
}
.chat-item__unseenMessages {
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  height: 18px;
  border-radius: 50%;
  font-size: 11px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  min-width: 18px;
  box-sizing: border-box;
  margin-left: 4px;
}
.chat-item__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.chat-item__messages {
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
  overflow: auto;
  margin-bottom: 5px;
}
.chat-item__footer {
  min-height: 40px;
  flex-shrink: 0;
}
.chat-item__message-container {
  display: flex;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 20%);
  background: #f0f2f5;
  border-radius: 100px;
  padding: 5px;
  box-sizing: border-box;
  margin: 0 5px;
  margin-bottom: 8px;
  /*margin-top: 5px;*/
}
.chat-item__message-container-input {
  flex-grow: 1;
  background: none;
  border: none;
  outline: none;
  padding: 0 10px;
  color: #444444;
  font-family: Montserrat-Light, sans-serif;
}
.chat-item__message-container-emoji {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.chat-item__message-container-emoji:hover .emoji-mart {
  display: inline-block !important;
}
.chat-item__message-container-emoji-img {
  width: 20px;
  margin-right: 10px;
}
.chat-item__message-container-button {
  border: none;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  border-radius: 100px;
  box-shadow: 0 4px 11px 0 rgb(0 0 0 / 20%);
  width: 35px;
  height: 35px;
}
.chat-item__message-container-button:disabled {
  opacity: 0.4;
  cursor: default;
}
.chat-item__message-container-button i {
  font-size: 21px;
  color: #ffffff;
}
.chat-item__time {
  align-self: center;
  font-size: 11px;
  color: #999;
  margin-bottom: 5px;
  margin-top: 10px;
}
.chat-item__my-message {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 3px;
}
.chat-item__my-message .chat-item__style {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  width: fit-content;
  padding: 9px 15px;
  color: #fff;
  font-size: 12px;
  border-radius: 8px 8px 0 8px;
  overflow-wrap: anywhere;
  max-width: 80%;
  font-family: Montserrat-Light, sans-serif;
  margin-right: 5px;
}
.chat-item__out-message {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 3px;
}
.chat-item__out-message .chat-item__style {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
  background: #f0f2f5;
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 8px 8px 8px 0;
  margin-left: 5px;
  width: fit-content;
  overflow-wrap: anywhere;
  max-width: 80%;
  color: #444444;
  font-family: Montserrat-Light, sans-serif;
}
.chat-item__loadMore {
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  border: none;
  font-family: Montserrat-Light, sans-serif;
  color: #ffffff;
  border-radius: 100px;
  padding: 8px 40px;
  width: fit-content;
  margin: 5px auto 0 auto;
  font-size: 12px;
}
.chat-item__startChat {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  border: none;
  color: #fff;
  font-family: Montserrat-Light, sans-serif;
  width: 100%;
  height: 100%;
}
.chat-item__file-item {
  display: flex;
  align-items: center;
}
.chat-item__my-message .chat-item__file-item span {
  color: #ffffff;
  font-size: 12px;
  margin-left: 5px;
}
.chat-item__out-message .chat-item__file-item span {
}
.react-kanban-column-header {
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 6px 10px;
  background: #f6f6f6;
}
.custom-kanban-create-button {
  border: 2px dashed #b5b5b5;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #7c7c7c;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 9px 10px;
  background: #fff;
}
.lead_funnel_dropdown {
  position: relative;
  margin-left: 5px;
}
.lead_funnel_dropdown__container {
  height: 38px;
  border: 1px solid #979797;
  min-width: 200px;
  border-radius: 4px;
  background: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.lead_funnel_dropdown__container span {
  font-size: 14px;
  color: #545454;
}
.lead_funnel_dropdown__container em {
  display: block;
  transform: rotate(90deg);
  color: #545454;
}
.lead_funnel_dropdown__dropdown {
  position: absolute;
  border: 1px solid #979797;
  width: 100%;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
  z-index: 3;
  left: 0;
  right: 0;
  top: 40px;
  padding: 6px 0;
}
.lead_funnel_dropdown__item {
  color: #545454;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
}
.lead_funnel_dropdown__item:hover {
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #ffffff;
}
.finance-list-icon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.cash-window {
}
.cash-window-flex {
  display: flex;
}
.cash-window-block {
  width: 33%;
  box-shadow: 1px 0px 20px rgb(0 0 0 / 20%);
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 340px;
}
.cash-window-block:nth-child(2n) {
  margin: 0 0.5%;
}
.cash-window-block h3 {
  font-weight: bold;
  font-family: "Montserrat-Black";
  color: #484848;
  text-align: center;
}
.new-bg-item {
  width: 24%;
  border: 2px solid #669295;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  margin: 0.5%;
  border-radius: 5px;
  box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
}
.new-bg-item i {
  font-size: 40px;
  color: #669295;
  margin-top: 3px;
}
.new-bg-item span {
  font-family: Montserrat-Light, sans-serif;
  color: #595959;
  font-size: 14px;
  text-transform: capitalize;
  padding: 1px 0;
  font-weight: 700;
  margin-bottom: 2px;
}
.new-bg-item button {
  color: #ffffff;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295);
  padding: 9px 50px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: fit-content;
  border: none;
  margin: 5px auto 5px;
}
.bp-tags-items {
  display: flex;
  flex-wrap: wrap;
}
.bp-tags-item {
  font-size: 12px;
  border: 1px solid #bbb;
  padding: 3px 10px;
  border-radius: 4px;
  margin: 3px 2px;
  cursor: pointer;
  color: #6e6e6e;
  font-weight: 400;
}
.bp-tags-item-active {
  border: 1px solid #669295;
  background: linear-gradient(45deg, #80aeba 2%, #699499 46%, #669295 100%);
  color: #ffffff;
}

.card-messengers-item {
  width: 40px;
  height: 40px;
  /*background: #FFFFFF;*/
  border-radius: 4px;
  padding: 3px;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;
}

.new-tabs {
}
.new-tabs__items {
  display: flex;
  margin-bottom: -1px;
  padding-left: 10px;
}
.new-tabs__item {
  font-size: 13px;
  padding: 8px 20px;
  cursor: pointer;
}
.new-tabs__item-active {
  border: 1px solid #bbb;
  border-bottom: 1px solid #f6f6f6;
  border-radius: 4px 4px 0 0;
}
.new-tabs__container {
  border-top: 1px solid #bbb;
  width: 100%;
}
.mini-table-delete-icon {
  width: 27px;
  height: 27px;
  background: linear-gradient(45deg, #d37873, #c96e6e 47%, #c16a6a);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 6px;
  cursor: pointer;
  text-decoration: none;
}
.img-popup-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(000, 000, 000, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-popup-bg img {
  max-width: 80%;
  max-height: 80%;
}
.img-popup-bg a {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(000, 000, 000, 0.7);
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #ffffff;
}
.list-min-button {
  height: 34px;
  width: 34px;
  border-radius: 4px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-min-button:hover {
  opacity: 0.6;
}
.rbc-addons-dnd * {
  font-family: Montserrat, sans-serif;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #679296;
}
.sp-table {
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 10px !important;
}
.new-th th {
  border: 1px solid #ababdb3d;
  border-right: 0;
  font-weight: 700;
  text-align: left;
  color: #818181;
  background: #efefef;
  padding: 8px 5px;
  font-size: 12px;
}
.new-th th:last-child {
  border-right: 1px solid #ababdb3d;
}
.new-tr td {
  border: 1px solid #ececed;
  border-right: 0;
  border-top: 0;
  background: #FFFFFF;
  padding: 7px 10px;
  min-width: 80px;
  cursor: pointer;
}
.new-tr th:last-child {
  border-right: 1px solid #ececed;
}
.modal-description-text {
  font-size: 13px;
  font-weight: 400;
  border: 1px solid #679397;
  padding: 8px 15px;
  background: #f5f5f5;
}

@media screen and (max-width: 1600px) {
  .kanban-cols-item {
    padding: 6px !important;
  }
  .kanbanItemStatus,
  .kanbanItemPriority {
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .kanbanItemDescription h3 {
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 16px !important;
  }
}

.relative {
  position: relative;
}

.dashbordFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.h50 {
  height: 50px;
}

.h325 {
  height: 325px;
}

.h345 {
  height: 345px;
}

.h360 {
  height: 360px;
}

.h396 {
  height: 396px;
}

.h500 {
  height: 500px;
}

.bold {
  font-weight: bold;
}

.m0 {
  margin: 0;
}

.date_bttn {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.fs15 {
  font-size: 15px;
}

.block {
  display: block;
}

.z_inx1 {
  z-index: 1;
}

.z_inx2 {
  z-index: 2;
}

@import "./accets/css/email.css";
@import "./accets/css/knowledgeBase.css";
