.settings-route {
    display: flex;
}
.settings-route__nav {
    width: 350px;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}
.settings-route__nav a {
    padding: 7px 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
.settings-route__nav i {
    font-size: 22px;
    color: #686868;
}
.settings-route__nav span {
    color: #686868;
    margin-left: 8px;
    font-size: 15px;
    font-weight: 400;
}
.settings-route__nav a.active, .settings-route__nav a:hover {
    background: #ededed;
}
.settings-route__component {
    flex-grow: 1;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    padding: 15px;
    margin-left: 15px;
}